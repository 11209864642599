<div class="book-thumb book-thumb--book --featured" (click)="selected.emit(book)">
    <div class="book-thumb__image-wrapper">
        <div
            class="book-thumb__image --top"
            [ngStyle]="{
                'background-image': book.featuredImageURL
                    ? 'url(' + book.featuredImageURL + ')'
                    : 'url(' + book.coverImageURL + ')'
            }"></div>
        <button class="book-thumb__user-fav button-v2 --icon-only --red" *ngIf="isFav">
            <fa-icon [icon]="['tg', 'heart-solid']"></fa-icon>
        </button>
        <div class="book-thumb__purchase-state" *ngIf="book.isForSale || book.isPurchased || book.isRented">
            <span class="doc-thumb__purchase-state__rental-expiry" *ngIf="book.isRented && book.isRentalActive">
                {{ getRentalDaysLeft() }} d left
            </span>
            <span class="doc-thumb__purchase-state__rental-expiry" *ngIf="book.isRented && !book.isRentalActive">
                Rental Expired
            </span>
            <div class="book-thumb__purchase-state__btn-container" [ngClass]="{ '--merge-corner': book.isRented }">
                <button
                    class="button-v2 --icon-only --bg-btn"
                    [ngClass]="{
                        '--buy': book.isForSale,
                        '--purchased': book.isPurchased,
                        '--rented': book.isRented && book.isRentalActive,
                        '--expired': book.isRented && !book.isRentalActive
                    }"
                    (click)="purchaseAction(); $event.stopPropagation()"></button>
            </div>
        </div>
    </div>
</div>
