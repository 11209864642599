<div class="doc-thumb doc-thumb--activity" (click)="selected.emit(activity)">
    <div class="doc-thumb__image-wrapper">
        <!--  <div class="doc-thumb__is-new-wrapper" *ngIf="activity.isNew">
            <div class="doc-thumb__is-new">New!</div>
        </div> -->
        <div class="doc-thumb__image" [style.backgroundImage]="'url(' + activity.coverImageURL + ')'"></div>
        <button class="doc-thumb__user-fav button-v2 --icon-only --red" *ngIf="isFav">
            <fa-icon [icon]="['tg', 'heart-solid']"></fa-icon>
        </button>
    </div>
    <div class="doc-thumb__title" *ngIf="!onlyImage">{{ activity.title }}</div>
    <!--     <div class="doc-thumb__subtitle" *ngIf="!onlyImage">Ages: {{ getAgeRange() }}</div>
 -->
</div>
