import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import { CreateFcmTokenInteractor, DeleteFcmTokenInteractor } from '@together/common';
import { SpringboardService } from './springboard.service';
import { App } from '@capacitor/app';
import { LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import { ActionPerformed as LocalNotificationActionPerformed } from '@capacitor/local-notifications';
@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class PushService {
    private deviceFcmToken: string;
    constructor(
        public http: HttpClient,
        private platform: Platform,
        private logger: AbstractLogger,
        private createTokenInteractor: CreateFcmTokenInteractor,
        private deleteTokenInteractor: DeleteFcmTokenInteractor,
        private springboardService: SpringboardService,
    ) {}

    public initialisePushNotification() {
        if (this.platform.is('capacitor')) {
            this.registerPush();
            PushNotifications.removeAllDeliveredNotifications();
        }
    }

    public async deleteFcmToken() {
        if (this.platform.is('capacitor')) {
            await this.deleteTokenInteractor.execute(this.deviceFcmToken);
            await PushNotifications.removeAllListeners();
        }
    }

    private async registerPush() {
        this.logger.info('Registering for push notification');
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            this.logger.error('User denied push permissions!');
        }
        await PushNotifications.register();
        this.createNotificationChannels();
        this.addListeners();
    }

    private createNotificationChannels() {
        PushNotifications.createChannel({
            id: 'call-notification-channel',
            name: 'Together Call Channel',
            visibility: 1,
            importance: 5,
            sound: 'receiving_call.mp3',
        }).then(r => this.logger.info('Together Call Notification Channel Created ' + r));
    }

    private addListeners() {
        PushNotifications.addListener('registration', async (token: Token) => {
            this.logger.info('Push token: ' + JSON.stringify(token));
            this.deviceFcmToken = token.value;
            const deviceInfo = await Device.getInfo();
            this.savePushToken(token.value, deviceInfo.name)
                .then(res => {
                    this.logger.info('FcmToken Created Successfully');
                })
                .catch(e => {
                    this.logger.error('Error while saving FcmToken: ', e);
                });
        });

        PushNotifications.addListener('registrationError', (error: any) => {
            this.logger.error('Error: ' + JSON.stringify(error));
        });

        PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
            this.logger.info('Push received: ' + JSON.stringify(notification));
            App.getState().then(async state => {
                if (state.isActive) {
                    this.logger.info('App is active, showing local notification');
                    await this.createLocalNotification(notification);
                    return;
                }
            });
        });

        PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
            const data = notification.notification.data;
            this.logger.info('Action performed: ' + JSON.stringify(notification.notification));
            if (data?.inboxUserMessageId) {
                this.logger.info('Navigating to inbox message');
                this.springboardService.openInbox(data.inboxUserMessageId);
            }
            await PushNotifications.removeAllDeliveredNotifications();
        });

        LocalNotifications.addListener(
            'localNotificationActionPerformed',
            async (notification: LocalNotificationActionPerformed) => {
                const data = notification.notification.extra;
                this.logger.info('Local Notification Action performed: ' + JSON.stringify(notification.notification));
                if (data?.inboxUserMessageId) {
                    this.logger.info('Navigating to inbox message');
                    this.springboardService.openInbox(data.inboxUserMessageId);
                }
            },
        );
    }

    async savePushToken(fcmToken: string, deviceName) {
        await this.createTokenInteractor.execute(fcmToken, deviceName);
    }

    private async createLocalNotification(notification: PushNotificationSchema) {
        const notificationObj: LocalNotificationSchema = {
            title: notification?.title,
            body: notification?.body,
            id: Math.floor(Math.random() * 1000),
            schedule: { at: new Date(Date.now() + 200) },
            extra: notification?.data,
        };
        await LocalNotifications.schedule({
            notifications: [notificationObj],
        });
    }
}
