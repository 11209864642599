<div class="modal-clean modal-clean--wide modal-invite --centered">
    <div class="modal-clean__content --no-outer-padding">
        <div class="modal-clean__header">
            <div class="modal__title">Add a Contact</div>
            <div class="modal__close" (click)="close()"></div>
        </div>
        <div class="modal-invite__container">
            <img class="modal-invite__hero" src="./assets/add-friends-group-full.png" />
            <form class="form" [formGroup]="emailForm">
                <p>Invite someone to connect on Together!</p>
                <div class="">
                    <input type="text" placeholder="Enter their email" formControlName="email" trim required />
                </div>
                <div class="form__input-errors" *ngIf="isControlInvalid(emailForm, 'email')">
                    <div *ngIf="emailForm.get('email').errors.required">Email is required.</div>
                    <div *ngIf="emailForm.get('email').errors.email">Email must be a valid email.</div>
                </div>
                <div class="btn-container">
                    <button class="button-v2 --purple --large --rounded --wide" (click)="sendInvite()" type="submit">
                        Send your Invitation
                    </button>
                </div>
            </form>
            <p class="divider-text" *ngIf="!isAndroid">Or</p>
            <p class="divider-text" *ngIf="isAndroid">Or send your invite another way</p>
            <div class="modal-invite__extra-actions">
                <div class="btn-container">
                    <button
                        class="button-v2 --purple --large --rounded --wide"
                        *ngIf="!isAndroid"
                        (click)="copyInvite()">
                        Copy Invite
                    </button>
                </div>
                <div class="modal-invite__share" *ngIf="isAndroid" (click)="shareInvite()">
                    <div class="modal-invite__share-btn button-v2 --icon-only --purple --shadow">
                        <fa-icon [icon]="['tg', 'share-link']"></fa-icon>
                    </div>
                    <span class="modal-invite__share-text">Share Invite</span>
                </div>
                <form class="form" [formGroup]="codeForm">
                    <a (click)="toggleInviteCodeForm()">Did you receive an invite code?<br />Enter it here</a>
                    <div class="" *ngIf="isInviteCodeFormVisible">
                        <input type="text" formControlName="shortInviteCode" trim autofocus placeholder="Invite Code" />
                        <div class="btn-container">
                            <button
                                class="button-v2 --purple --rounded --wide --large"
                                (click)="enterInviteCode()"
                                [disabled]="!codeForm.valid">
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
