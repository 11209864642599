export default [
    {
        prefix: 'tg',
        iconName: 'accept-call',
        icon: [
            36,
            36,
            [],
            'e000',
            'M31.22 22.28l-6.6-2.82a2.2 2.2 0 00-2.12.19l-3.53 2.34a10.76 10.76 0 01-4.95-4.92l2.33-3.57a2.24 2.24 0 00.2-2.12l-2.83-6.6a2.3 2.3 0 00-2.34-1.35 7.9 7.9 0 00-6.88 7.82A20.27 20.27 0 0024.75 31.5a7.9 7.9 0 007.82-6.88 2.3 2.3 0 00-1.35-2.34z M22.13 6.7a10.2 10.2 0 017.16 7.17 1.13 1.13 0 002.06.27 1.11 1.11 0 00.1-.85 12.37 12.37 0 00-8.74-8.75 1.13 1.13 0 10-.58 2.17z M20.97 11.05a5.68 5.68 0 013.98 3.98 1.11 1.11 0 001.76.61 1.11 1.11 0 00.4-1.18 7.85 7.85 0 00-5.57-5.57 1.13 1.13 0 10-.57 2.16z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'apple-logo',
        icon: [
            24,
            24,
            [],
            'e001',
            'M16.34 1s-1.64.04-3.14 1.54c-1.5 1.5-1.25 3.52-1.25 3.52s1.49.27 3.07-1.31C16.61 3.16 16.34 1 16.34 1z M12.12 7.34c-1.1 0-1.78-.96-3.66-.96-1.7 0-3.4 1.1-4.12 2.15A7.13 7.13 0 003 13.08c0 2.34 1.13 6.37 3.9 9.1.48.47 1.1.82 1.82.84 1.3.04 2.09-.93 3.72-.93 1.63 0 2.12.93 3.59.93.67 0 1.43-.3 2.17-1.1a14.14 14.14 0 002.74-4.78s-3.01-1.16-2.98-4.51c.03-2.78 2.4-4.14 2.4-4.14s-1.39-2.2-4.31-2.2c-2.03.02-2.93 1.06-3.93 1.05z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'arrow-down',
        icon: [
            448,
            512,
            [],
            'e002',
            'M413.1 222.5l22.2 22.2a23.9 23.9 0 010 33.9L241 473a23.9 23.9 0 01-33.9 0L12.7 278.6a23.9 23.9 0 010-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'arrow-left',
        icon: [
            24,
            23,
            [],
            'e003',
            'M22.25 7.9c-.62-.34-4.52-1.2-8.4-1.79V1.25A1.24 1.24 0 0011.82.3C5.8 4.11.5 9.95.3 10.2c-.4.47-.4 1.16 0 1.63.21.25 5.6 6.2 11.66 10 .83.52 1.9-.08 1.9-1.06v-4.88c3.87-.6 7.77-1.44 8.4-1.78A3.3 3.3 0 0024 11c0-1.57-.7-2.54-1.75-3.12z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'arrow-right',
        icon: [
            24,
            23,
            [],
            'e004',
            'M1.75 15.1c.62.34 4.52 1.2 8.4 1.79v4.86a1.24 1.24 0 002.04.95c6-3.81 11.3-9.65 11.51-9.9.4-.47.4-1.16 0-1.63-.21-.25-5.6-6.2-11.66-10-.83-.52-1.9.08-1.9 1.06v4.88c-3.87.6-7.77 1.44-8.4 1.78A3.3 3.3 0 000 12c0 1.57.7 2.54 1.75 3.12z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'avatar-female',
        icon: [34, 34, [], 'e005', 'M17 0a17 17 0 100 34 17 17 0 100-34z M17 0a17 17 0 100 34 17 17 0 100-34z'],
    },
    {
        prefix: 'tg',
        iconName: 'avatar-male',
        icon: [34, 34, [], 'e006', 'M17 1.5a15.5 15.5 0 100 31 15.5 15.5 0 100-31z'],
    },
    {
        prefix: 'tg',
        iconName: 'badge-star',
        icon: [
            512,
            512,
            [],
            'e007',
            'M265 123.7l18.6 57.7 60.6-.2c4.3 0 7.9 2.6 9.3 6.7 1.3 4.1-.1 8.3-3.6 10.8l-49 35.6 18.8 57.6c1.3 4.1-.1 8.3-3.5 10.8a9.6 9.6 0 01-11.4 0l-49-35.7-49 35.7a9.6 9.6 0 01-11.4 0 9.59 9.59 0 01-3.5-10.8l18.8-57.6-49.1-35.6a9.59 9.59 0 01-3.5-10.8c1.3-4.1 4.9-6.7 9.2-6.7l60.6.2 18.6-57.7c1.3-4.1 4.9-6.7 9.2-6.7 4.4 0 8 2.7 9.3 6.7z M192.7 377.5A178.78 178.78 0 0189.1 273.9L3 360l98.9 4.7 4.7 98.8 86.1-86z M422.4 273.9a178.78 178.78 0 01-103.6 103.6l86.1 86 4.7-98.8 98.9-4.7-86.1-86.1z M405.8 148.7c-8.2-19.9-20.3-37.8-35.3-52.7-14.9-15-32.8-27-52.7-35.3-19.1-7.9-40.1-12.3-62.1-12.3s-43 4.4-62.1 12.3a163.79 163.79 0 00-87.9 88c-7.9 19.1-12.3 40.1-12.3 62.1A162.22 162.22 0 00141 325.7c15 14.9 32.8 27 52.8 35.2 19.1 8 40.1 12.4 62.1 12.4 44.8 0 85.4-18.2 114.8-47.6a162.22 162.22 0 0047.6-114.9c-.2-21.9-4.5-42.9-12.5-62.1zm-28.2 112.5a132.6 132.6 0 01-28.7 42.8c-12.1 12.2-26.7 22-42.8 28.7-15.5 6.4-32.5 9.9-50.4 9.9s-34.9-3.5-50.4-9.9a132.6 132.6 0 01-42.8-28.7c-12.2-12.1-22-26.7-28.7-42.8-6.4-15.5-9.9-32.5-9.9-50.4s3.6-34.9 9.9-50.4A132.6 132.6 0 01205.3 89c15.5-6.4 32.5-10 50.4-10s34.9 3.6 50.4 10c16.1 6.7 30.6 16.5 42.8 28.6 12.2 12.2 22 26.7 28.7 42.8 6.4 15.5 10 32.5 10 50.4s-3.5 35-10 50.4z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'bag',
        icon: [
            21,
            20,
            [],
            'e008',
            'M13.8 10.2a1.38 1.38 0 110-2.76 1.38 1.38 0 010 2.76zM8.54 5.53V4.46c0-1.23.89-2.3 1.98-2.3 1.08 0 1.97 1.07 1.97 2.3v1.07H8.53zm-1.3 4.67a1.38 1.38 0 110-2.76 1.38 1.38 0 010 2.76zm13.12 7.9c-.53-3.82-1.05-7.73-1.58-11.72a.99.99 0 00-.98-.85h-3.34V4.46c0-2.32-1.77-4.2-3.94-4.2-2.18 0-3.95 1.88-3.95 4.2v1.07H3.22c-.5 0-.91.36-.98.85-.53 4-1.05 7.9-1.58 11.71a.98.98 0 00.8 1.1 52.36 52.36 0 0018.09 0 .98.98 0 00.8-1.1z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'book',
        icon: [
            28,
            24,
            [],
            'e009',
            'M10 20a3.01 3.01 0 013 3 1 1 0 002 0 3.01 3.01 0 013-3h8a2 2 0 002-2V2a2 2 0 00-2-2h-6a5 5 0 00-5 5v10a1 1 0 01-2 0V5a5 5 0 00-5-5H2a2 2 0 00-2 2v16a2 2 0 002 2h8z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'bulb',
        icon: [
            22,
            29,
            [],
            'e010',
            'M17 28a1 1 0 01-1 1H6a1 1 0 110-2h10a1 1 0 011 1zm5-16a10.96 10.96 0 01-4.2 8.65 2.01 2.01 0 00-.8 1.6V23a2 2 0 01-2 2H7a2 2 0 01-2-2v-.75a2.03 2.03 0 00-.78-1.59A10.97 10.97 0 010 12.06a11.05 11.05 0 0115.03-10.3A10.99 10.99 0 0122 12zm-4.1-1.18a6.98 6.98 0 00-5.72-5.7 1 1 0 00-.33 1.97 4.96 4.96 0 014.08 4.07 1 1 0 00.98.83h.18a.99.99 0 00.81-1.17z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'call-ended',
        icon: [
            48,
            48,
            [],
            'e011',
            'M41.63 29.7l-8.8-3.75a2.92 2.92 0 00-2.83.24l-4.7 3.13a14.34 14.34 0 01-6.6-6.56L21.8 18a2.98 2.98 0 00.27-2.83l-3.77-8.8a3.06 3.06 0 00-3.13-1.8A10.54 10.54 0 006 15c0 14.89 12.11 27 27 27a10.54 10.54 0 0010.42-9.17 3.06 3.06 0 00-1.8-3.13z M28.93 19.07a1.54 1.54 0 002.14 0l3.43-3.45 3.43 3.45a1.54 1.54 0 002.14 0 1.52 1.52 0 000-2.14l-3.45-3.43 3.45-3.43a1.52 1.52 0 00-2.14-2.14l-3.43 3.45-3.43-3.45a1.52 1.52 0 00-2.14 2.14l3.45 3.43-3.45 3.43a1.52 1.52 0 000 2.14z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'camera-toggle',
        icon: [
            32,
            32,
            [],
            'e012',
            'M26 7h-3.46l-1.7-2.55A.99.99 0 0020 4h-8a.99.99 0 00-.84.45L9.46 7H6a3.01 3.01 0 00-3 3v14a3.01 3.01 0 003 3h20a3.01 3.01 0 003-3V10a3.01 3.01 0 00-3-3zm-5.76 13.74a5.97 5.97 0 01-8.48 0h-.01l-.59-.61-.17 1.03A1.01 1.01 0 0110 22h-.16A1 1 0 019 20.85l.5-3a1.01 1.01 0 01.83-.83l3-.5a1 1 0 11.32 1.98l-1.12.19.64.66a4 4 0 005.64-.03 1 1 0 011.42 1.42zm2.75-8.58l-.5 3a1.01 1.01 0 01-.83.83l-3 .5h-.16a1.01 1.01 0 01-.97-1.23 1 1 0 01.8-.76l1.13-.19-.64-.66a4 4 0 00-5.64.03 1 1 0 01-1.42-1.42 5.97 5.97 0 018.49.02l.59.6.17-1.04a1 1 0 011.98.32z M0 0h32v32H0z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'camera',
        icon: [
            26,
            23,
            [],
            'e013',
            'M23 3h-3.46L17.84.44A1 1 0 0017 0H9a1 1 0 00-.83.44L6.47 3H3a3 3 0 00-3 3v14a3 3 0 003 3h20a3 3 0 003-3V6a3 3 0 00-3-3zm-5.5 9.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'chat',
        icon: [
            26,
            26,
            [],
            'e014',
            'M13 0A13 13 0 001.6 19.25L.54 22.99a2.01 2.01 0 001.41 2.5c.35.08.7.07 1.05-.03l3.75-1.06A13 13 0 1013 0zM7 14.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm6 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm6 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'check',
        icon: [
            512,
            512,
            [],
            'e015',
            'M173.9 439.4L7.5 273c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 312.69l240.1-240.1c10-10 26.2-10 36.2 0l36.2 36.21c10 10 10 26.2 0 36.2L210.1 439.4c-10 10-26.2 10-36.2 0z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'checked-round',
        icon: [
            20,
            20,
            [],
            'e016',
            'M10 .25a9.75 9.75 0 100 19.5 9.75 9.75 0 000-19.5zm4.64 8.04l-5.5 5.25a.76.76 0 01-1.03 0l-2.75-2.62a.75.75 0 111.03-1.09l2.23 2.13 4.99-4.75a.75.75 0 011.03 1.08z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'checked',
        icon: [
            19,
            14,
            [],
            'e017',
            'M2.9 5.78a1.26 1.26 0 10-1.8 1.77l1.8-1.77zM7.22 12l-.9.88.9.93.9-.93-.9-.88zM17.9 2.88a1.26 1.26 0 10-1.8-1.76l1.8 1.76zM1.1 7.55l5.21 5.33 1.81-1.76L2.9 5.78 1.1 7.55zm7.02 5.33l9.78-10-1.8-1.76-9.79 10 1.81 1.76z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'chevron-down-round',
        icon: [
            26,
            26,
            [],
            'e018',
            'M26 13a13 13 0 10-26 0 13 13 0 0026 0zm-13.7 3.7l-5-5a1 1 0 111.4-1.4l4.3 4.29 4.3-4.3a1 1 0 011.4 1.42l-5 5a1 1 0 01-1.4 0z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'chevron-down',
        icon: [18, 18, [], 'e019', 'M18 0h18v18H18z'],
    },
    {
        prefix: 'tg',
        iconName: 'chevron-left',
        icon: [
            320,
            512,
            [],
            'e020',
            'M34.52 239.03L228.87 44.69a24 24 0 0133.94 0l22.67 22.67a24 24 0 01.04 33.9L131.49 256l154.02 154.75a24 24 0 01-.04 33.9l-22.67 22.67a24 24 0 01-33.94 0L34.52 272.97a24 24 0 010-33.94z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'chevron-right',
        icon: [
            320,
            512,
            [],
            'e021',
            'M285.48 272.97L91.13 467.31a24 24 0 01-33.94 0l-22.67-22.66a24 24 0 01-.04-33.9L188.5 256 34.48 101.25a24 24 0 01.04-33.9L57.2 44.7a24 24 0 0133.94 0l194.35 194.34a24 24 0 010 33.94z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'chevron-up',
        icon: [18, 18, [], 'e022', 'M0 18h18v18H0z'],
    },
    {
        prefix: 'tg',
        iconName: 'child-face',
        icon: [
            36,
            36,
            [],
            'e023',
            'M18.87 3.4a.55.55 0 00-.5.26 8.72 8.72 0 00-1.5 4.21 1.11 1.11 0 001.37 1.1c.52-.1.87-.59.89-1.12a1.14 1.14 0 011.23-1.1 1.15 1.15 0 011.02 1.17 3.38 3.38 0 01-6.75-.05c0-.08.01-1.51.76-3.37a.56.56 0 00-.65-.76 14.64 14.64 0 003.02 28.88A14.62 14.62 0 0018.87 3.4zM11.25 18a1.69 1.69 0 113.38 0 1.69 1.69 0 01-3.38 0zm11.11 6.57a7.92 7.92 0 01-8.72 0 1.14 1.14 0 01.4-2.05 1.12 1.12 0 01.85.16 5.6 5.6 0 006.22 0 1.13 1.13 0 011.72 1.17c-.06.3-.22.55-.47.72zm.7-4.88a1.69 1.69 0 110-3.38 1.69 1.69 0 010 3.38z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'clock',
        icon: [
            20,
            20,
            [],
            'e024',
            'M10 .25a9.75 9.75 0 100 19.5 9.75 9.75 0 000-19.5zm5.25 10.5H10a.75.75 0 01-.75-.75V4.75a.75.75 0 011.5 0v4.5h4.5a.75.75 0 110 1.5z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'cog',
        icon: [
            24,
            24,
            [],
            'e025',
            'M12 16.08a4.08 4.08 0 110-8.16 4.08 4.08 0 010 8.16zm11.94-5.23a1.2 1.2 0 00-1.04-1.07l-2.04-.26a1.34 1.34 0 01-.84-2.03l1.26-1.63a1.2 1.2 0 00-.02-1.49c-.49-.6-1.04-1.15-1.63-1.63a1.2 1.2 0 00-1.49-.02l-1.61 1.25c-.8.53-1.87.07-2.06-.86l-.25-2A1.2 1.2 0 0013.15.05c-.76-.08-1.54-.08-2.3 0-.56.06-1 .49-1.07 1.04L9.53 3.1a1.33 1.33 0 01-2.09.85L5.86 2.72a1.2 1.2 0 00-1.5.02c-.59.49-1.14 1.04-1.62 1.63a1.2 1.2 0 00-.02 1.5l1.21 1.55c.6.82.11 1.96-.87 2.1l-1.96.26a1.2 1.2 0 00-1.04 1.07c-.08.77-.08 1.55 0 2.3.06.55.49 1 1.04 1.07l1.95.24c1 .15 1.49 1.3.88 2.12l-1.2 1.56a1.2 1.2 0 00.01 1.49c.5.6 1.04 1.15 1.63 1.63a1.2 1.2 0 001.49.02l1.58-1.22c.8-.58 1.92-.12 2.1.85l.24 2c.07.55.51.97 1.07 1.03a12.27 12.27 0 002.3 0c.55-.06 1-.48 1.07-1.04l.25-2.01a1.34 1.34 0 012.06-.86l1.61 1.25a1.2 1.2 0 001.5-.02c.59-.49 1.14-1.04 1.62-1.63a1.2 1.2 0 00.02-1.49l-1.26-1.63a1.33 1.33 0 01.84-2.03l2.04-.26a1.2 1.2 0 001.04-1.07c.08-.77.08-1.54 0-2.3z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'dice',
        icon: [
            24,
            24,
            [],
            'e026',
            'M17.61 8.91a2.54 2.54 0 01-2.52-2.53 2.54 2.54 0 012.52-2.52 2.54 2.54 0 012.53 2.52 2.54 2.54 0 01-2.53 2.53zm0 11.23a2.54 2.54 0 01-2.52-2.53 2.54 2.54 0 012.52-2.52 2.54 2.54 0 012.53 2.52 2.54 2.54 0 01-2.53 2.53zM12 14.52A2.54 2.54 0 019.47 12 2.54 2.54 0 0112 9.47 2.54 2.54 0 0114.53 12 2.54 2.54 0 0112 14.52zM6.39 8.91a2.54 2.54 0 01-2.53-2.53 2.54 2.54 0 012.53-2.52A2.54 2.54 0 018.9 6.38a2.54 2.54 0 01-2.5 2.53zm0 11.23a2.54 2.54 0 01-2.53-2.53 2.54 2.54 0 012.53-2.52A2.54 2.54 0 018.9 17.6a2.54 2.54 0 01-2.52 2.53zM23.05 3.37A3.18 3.18 0 0020.63.94a39.9 39.9 0 00-17.26 0A3.18 3.18 0 00.95 3.37a39.79 39.79 0 000 17.26 3.18 3.18 0 002.42 2.42 39.77 39.77 0 0017.26 0 3.18 3.18 0 002.42-2.42 39.79 39.79 0 000-17.26z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'ellipsis',
        icon: [
            42,
            42,
            [],
            'e027',
            'M25.6 21a4.6 4.6 0 11-9.2 0 4.6 4.6 0 019.2 0zM7.86 16.4a4.6 4.6 0 100 9.2 4.6 4.6 0 000-9.2zm26.25 0a4.6 4.6 0 100 9.2 4.6 4.6 0 000-9.2z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'error',
        icon: [
            40,
            40,
            [],
            'e028',
            'M20 .5a19.5 19.5 0 100 39 19.5 19.5 0 000-39zm7.07 24.43a1.52 1.52 0 010 2.14 1.54 1.54 0 01-2.14 0L20 22.12l-4.93 4.95a1.54 1.54 0 01-2.14 0 1.52 1.52 0 010-2.14L17.88 20l-4.95-4.93a1.52 1.52 0 012.14-2.14L20 17.88l4.93-4.95a1.52 1.52 0 012.14 2.14L22.12 20l4.95 4.93z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'exclamation-note',
        icon: [
            18,
            20,
            [],
            'e029',
            'M16.5.64h-15A1.5 1.5 0 000 2.14v5.5c0 8.38 7.1 11.17 8.53 11.64.3.11.64.11.94 0C10.89 18.8 18 16.02 18 7.64v-5.5a1.5 1.5 0 00-1.5-1.5zM8.25 5.89a.75.75 0 011.5 0v3.75a.75.75 0 01-1.5 0V5.89zM9 14.14a1.13 1.13 0 110-2.25 1.13 1.13 0 010 2.25z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'exclamation-round',
        icon: [
            21,
            20,
            [],
            'e030',
            'M10.5.25a9.75 9.75 0 100 19.5 9.75 9.75 0 000-19.5zM9.75 5.5a.75.75 0 011.5 0v5.25a.75.75 0 11-1.5 0V5.5zm.75 9.75a1.13 1.13 0 110-2.25 1.13 1.13 0 010 2.25z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'exclamation',
        icon: [
            42,
            38,
            [],
            'e031',
            'M41.38 31.25L24.9 2.75a4.5 4.5 0 00-7.8 0L.62 31.25A4.48 4.48 0 004.5 38h33a4.48 4.48 0 003.88-6.75zM19.5 15.5a1.5 1.5 0 113 0V23a1.5 1.5 0 01-3 0v-7.5zM21 32a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'face',
        icon: [
            512,
            512,
            [],
            'e032',
            'M346 220c-19.9 0-36 16.1-36 36s16.1 36 36 36 36-16.1 36-36-16.1-36-36-36zm-90 252c-119.3 0-216-96.7-216-216 0-26.2 4.9-51.2 13.5-74.5C60.8 183 68.2 184 76 184c54.6 0 100.7-35 118.2-83.6 32 50.2 87.9 83.6 151.8 83.6 37.1 0 71.5-11.3 100.1-30.5C462.6 184 472 218.9 472 256c0 119.3-96.7 216-216 216zm0-468C116.8 4 4 116.8 4 256s112.8 252 252 252 252-112.8 252-252S395.2 4 256 4zm-90 288c19.9 0 36-16.1 36-36s-16.1-36-36-36-36 16.1-36 36 16.1 36 36 36zm188.4 46.7c-3.5 0-13.6 7-13.4 6.8 0 0-31.1 18-85.1 18s-85.1-18-85.1-18-9.8-6.8-13.4-6.8c-9.3 0-18 7.6-18 16.9 0 10.7 12.4 18 12.4 18s50.6 25.9 104 25.9 104-25.9 104-25.9 12.4-6.7 12.4-18c.2-9.3-8.5-16.9-17.8-16.9z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'gallery',
        icon: [
            22,
            22,
            [],
            'e033',
            'M10 2v6a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h6a2 2 0 012 2zm10-2h-6a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2V2a2 2 0 00-2-2zM8 12H2a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2v-6a2 2 0 00-2-2zm12 0h-6a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2v-6a2 2 0 00-2-2z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'game',
        icon: [
            31,
            23,
            [],
            'e034',
            'M29.94 17.71h-.01L27.89 7.15l-.01-.02A7.5 7.5 0 0020.5.96L9.5 1a7.49 7.49 0 00-7.39 6.2v.03L.08 17.7a4.49 4.49 0 004.42 5.29c1.2 0 2.34-.48 3.19-1.31l.05-.07L12.83 16l4.36-.03 5.07 5.66.07.07a4.5 4.5 0 007.6-3.98zM12 9.5h-1v1a1 1 0 01-2 0v-1H8a1 1 0 110-2h1v-1a1 1 0 012 0v1h1a1 1 0 010 2zm5-1a1 1 0 011-1h4a1 1 0 010 2h-4a1 1 0 01-1-1zm8.94 12.46a2.51 2.51 0 01-2.19-.67l-3.89-4.31h.64a7.5 7.5 0 006.38-3.54l1.08 5.65a2.5 2.5 0 01-2.02 2.87z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'gift',
        icon: [
            22,
            22,
            [],
            'e035',
            'M19.78 6.19h-3.6a3.3 3.3 0 00-4.87-4.42 3 3 0 00-.58.85 3 3 0 00-.6-.85A3.3 3.3 0 005.28 6.2h-3.6A1.65 1.65 0 00.02 7.83v3.3a1.65 1.65 0 001.65 1.64v6.59A1.65 1.65 0 003.32 21h14.81a1.65 1.65 0 001.65-1.64v-6.59a1.65 1.65 0 001.65-1.64v-3.3a1.65 1.65 0 00-1.65-1.64zm-7.3-3.25a1.65 1.65 0 112.32 2.32c-.5.52-1.98.8-3.22.9.1-1.25.38-2.72.9-3.22zm-5.83 0a1.66 1.66 0 012.33 0c.51.5.79 1.97.9 3.22-1.25-.1-2.73-.38-3.23-.9a1.66 1.66 0 010-2.32zm13.13 8.19h-8.23v7.4a.82.82 0 01-1.65 0v-7.4H1.67v-3.3H9.9v3.3h1.65v-3.3h8.23v3.3z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'google-logo',
        icon: [
            20,
            20,
            [],
            'e036',
            'M19.76 10.23c0-.68-.05-1.36-.17-2.03h-9.43v3.85h5.4a4.63 4.63 0 01-2 3.04v2.5h3.22c1.9-1.75 2.98-4.32 2.98-7.36z M10.16 20c2.7 0 4.97-.88 6.62-2.41l-3.22-2.5c-.9.6-2.05.95-3.4.95a5.99 5.99 0 01-5.61-4.12H1.22v2.57A10 10 0 0010.16 20z M4.54 11.92a5.99 5.99 0 010-3.83V5.5H1.22a10 10 0 000 8.98l3.32-2.57z M10.16 3.96c1.42-.02 2.8.51 3.83 1.5l2.86-2.86A10 10 0 001.22 5.51L4.54 8.1c.8-2.37 3-4.13 5.62-4.13z M0 0h20v20H0z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'heart-empty',
        icon: [
            18,
            17,
            [],
            'e037',
            'M14.86 7.95c-1.82 3.87-5.15 6.26-5.9 6.33-.73-.07-4.06-2.46-5.88-6.33-1.7-3.64-.03-5.58 1.98-5.58 1.8 0 2.88 1.65 3.26 3.3.08.35.39.57.74.58a.73.73 0 00.7-.62c.2-1.4 1.17-3.26 3.12-3.26 2 0 3.68 1.94 1.98 5.58zm2.33-5.7A4.94 4.94 0 0012.9 0C11.22 0 9.98.6 9 1.7A4.98 4.98 0 005.09 0C3.3 0 1.67.8.75 2.25.2 3.12-.68 5.67.87 8.98c2.01 4.28 6.07 7.73 8.1 7.73 2.02 0 6.09-3.45 8.1-7.73 1.62-3.46.77-5.7.12-6.73z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'heart-solid',
        icon: [
            18,
            17,
            [],
            'e038',
            'M11.36.74C9.46 2.05 9 3.85 9 3.85S8.54 2.05 6.64.74C3.5-1.43-2.27 1.21.94 8.02c2.38 5.05 6.83 8.06 8.06 8.06 1.23 0 5.68-3.01 8.06-8.06 3.21-6.81-2.57-9.45-5.7-7.28z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'house',
        icon: [
            24,
            24,
            [],
            'e039',
            'M15.1 19.62c-.02.43-.32.78-.7.83-1.57.19-3.17.2-4.8 0-.38-.05-.68-.4-.7-.83l-.2-3.56c-.02-.51.34-.94.8-.94h5c.46 0 .82.43.8.94l-.2 3.56zm8.57-7.64a37.26 37.26 0 00-2.87-3.9V.87C20.8.4 20.44 0 20 0h-2.4c-.44 0-.8.4-.8.89V3.8A52.56 52.56 0 0012.68.23a1.1 1.1 0 00-1.36 0c-.28.22-7 5.37-11.1 11.9-.56.88.01 2.1.98 2.1h1.98l1 7.87c.08.56.46 1 .95 1.12a30.54 30.54 0 0013.74 0c.5-.12.87-.56.94-1.12l1-7.87h1.99c1.06 0 1.59-1.41.87-2.25z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'included',
        icon: [
            18,
            17,
            [],
            'e040',
            'M15.32.72c-.37-.2-.86-.06-1.13.33l-6.6 9.4-3.86-5.44c-.27-.4-.77-.54-1.13-.34-.84.46-1.62 1-2.34 1.64-.32.27-.35.79-.07 1.18l5.7 8.04a2.06 2.06 0 003.42 0c.64-.88 8.42-12 8.42-12 .27-.39.24-.9-.07-1.17-.73-.64-1.5-1.18-2.34-1.64z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'info',
        icon: [
            28,
            28,
            [],
            'e041',
            'M14 0a14 14 0 100 28 14 14 0 000-28zm-.27 6.46a1.62 1.62 0 110 3.23 1.62 1.62 0 010-3.23zm1.35 15.08H14a1.08 1.08 0 01-1.08-1.08V14a1.08 1.08 0 010-2.15H14a1.08 1.08 0 011.08 1.07v6.46a1.08 1.08 0 010 2.16z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'lock-alt',
        icon: [
            512,
            512,
            [],
            'e042',
            'M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zM264 392c0 22.1-17.9 40-40 40s-40-17.9-40-40v-48c0-22.1 17.9-40 40-40s40 17.9 40 40v48zm32-168H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'mic-close',
        icon: [
            36,
            36,
            [],
            'e043',
            'M30.08 29.62L7.58 4.87a1.13 1.13 0 00-1.66 1.51l5.33 5.87V18a6.75 6.75 0 0010.4 5.68l1.52 1.69A9 9 0 019.06 19a1.13 1.13 0 00-2.24.25 11.24 11.24 0 0010.05 9.94v3.43a1.13 1.13 0 002.25 0V29.2c2.02-.2 3.95-.94 5.57-2.15l3.73 4.1a1.12 1.12 0 101.66-1.52z M22.8 18.25a1.11 1.11 0 00.83.37c.14 0 .27-.02.4-.07a1.13 1.13 0 00.72-1.06V9a6.75 6.75 0 00-12.49-3.54 1.12 1.12 0 00.12 1.35L22.8 18.25z M27.03 22.74c.13.04.27.07.4.07a1.1 1.1 0 001.05-.72c.35-.9.6-1.86.7-2.84a1.13 1.13 0 00-2.24-.25 8.45 8.45 0 01-.56 2.28 1.14 1.14 0 00.65 1.46z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'mic-open',
        icon: [
            36,
            36,
            [],
            'e044',
            'M18 24.75A6.75 6.75 0 0024.75 18V9a6.75 6.75 0 00-13.5 0v9A6.75 6.75 0 0018 24.75z M28.18 18.01a1.13 1.13 0 00-1.24.99 9 9 0 01-17.88 0 1.13 1.13 0 00-1.66-.86 1.1 1.1 0 00-.58 1.11 11.2 11.2 0 0010.05 9.9v3.48a1.12 1.12 0 102.25 0v-3.48a11.2 11.2 0 0010.06-9.9 1.11 1.11 0 00-1-1.24z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'microphone',
        icon: [
            360,
            512,
            [],
            'e045',
            'M180 356.4c55.4 0 100.4-45.1 100.4-100.4V105.4C280.4 50 235.4 5 180 5S79.6 50 79.6 105.4V256c0 55.3 45 100.4 100.4 100.4z M355.7 256v-50.2c0-13.9-11.2-25.1-25.1-25.1-13.9 0-25.1 11.2-25.1 25.1V256c0 69.2-56.3 125.5-125.5 125.5S54.5 325.2 54.5 256v-50.2c0-13.9-11.2-25.1-25.1-25.1S4.3 191.9 4.3 205.8V256c0 88.4 65.6 161.4 150.6 173.7v27.1H79.6c-13.9 0-25.1 11.2-25.1 25.1S65.7 507 79.6 507h200.8c13.9 0 25.1-11.2 25.1-25.1s-11.2-25.1-25.1-25.1h-75.3v-27.1c85-12.3 150.6-85.3 150.6-173.7z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'pencil',
        icon: [
            23,
            24,
            [],
            'e046',
            'M20.53 16.9a2.33 2.33 0 00-2.32 2.34 2.33 2.33 0 002.32 2.34 2.33 2.33 0 002.32-2.34 2.33 2.33 0 00-2.32-2.33zm-5.94 3.17c-1.08 0-1.96.88-1.96 1.96a1.96 1.96 0 101.95-1.97zm6.82-20c-2.76.98-5.68 2-8.49 2.93-.17.06-.34.16-.47.3L3.82 12l1.68 1.68 8.44-8.5c1.07-.35 2.16-.72 3.25-1.1l1.75 1.77-1.1 3.27-8.44 8.5 1.68 1.69 8.65-8.7c.13-.13.23-.29.28-.47.94-2.87 2-5.92 2.92-8.54A1.2 1.2 0 0021.41.07zM.01 17.87c.21 2.47 3 5.28 5.56 5.28 1.94 0 3-1.3 4.01-2.33L2.31 13.5c-1.03 1.05-2.47 2.22-2.3 4.38zM16.34 6.74a1.18 1.18 0 00-1.68 0l-8.03 8.08 1.68 1.7 8.03-8.08a1.2 1.2 0 000-1.7z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'phone-alt',
        icon: [
            512,
            512,
            [],
            'e047',
            'M294.2 294.2c-49.2 49.1-106.1 96.1-128.6 73.6-32.2-32.2-52.1-60.3-123.1-3.2S26 459.8 57.2 491c36 36 170.3 1.9 303-130.8S527 93.2 491 57.2C459.8 26 421.7-28.6 364.6 42.5c-57.1 71-29 90.9 3.2 123.1 22.5 22.5-24.5 79.4-73.6 128.6z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'phone-down',
        icon: [
            512,
            512,
            [],
            'e048',
            'M256 212.3c56.9 0 116.9 5.7 116.9 31.7 0 37.2-4.7 64.9 69.3 73 74 8 64.5-45.4 64.5-81.5 0-41.6-97.3-99.4-250.6-99.4C102.6 136 5.3 193.8 5.4 235.5c0 36.1-9.5 89.6 64.5 81.5s69.2-35.7 69.3-73c-.1-26 60-31.7 116.8-31.7z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'phone-o',
        icon: [
            36,
            36,
            [],
            'e049',
            'M34.64 26.02l-8.51-4.46c-.6-.29-1.05-.1-1.47.24l-3.9 3.58c-.47.42-1.06.5-1.55.2a26.6 26.6 0 01-8.86-8.85c-.3-.5-.23-1.14.18-1.55l3.68-3.68h-.01c.5-.42.66-1.06.27-1.69l-4.71-8.6-.02.01A1.23 1.23 0 008.3.6c-2.09.2-3.89.95-5.25 2.3-4.82 4.84-2.03 15.45 6.25 23.73 8.28 8.28 18.9 11.08 23.72 6.25a8.18 8.18 0 002.33-5.37c.05-.83-.19-1.22-.7-1.49',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'phone',
        icon: [
            512,
            512,
            [],
            'e050',
            'M218 294c49 49 105.7 95.8 128.1 73.4 32.1-32.1 51.9-60 122.6-3.2S485.1 458.9 454 490c-35.9 35.9-169.6 1.9-301.7-130.3C20.1 227.6-13.9 93.9 22 58c31.1-31.1 69-85.4 125.8-14.7 56.9 70.7 28.9 90.5-3.2 122.6C122.3 188.3 169.1 245 218 294z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'play-time',
        icon: [
            33,
            33,
            [],
            'e051',
            'M31.5 19.5a1 1 0 01-1 1h-2v2a1 1 0 11-2 0v-2h-2a1 1 0 010-2h2v-2a1 1 0 012 0v2h2a1 1 0 011 1zM8.5 9H10v1.5a1 1 0 002 0V9h1.5a1 1 0 100-2H12V5.5a1 1 0 10-2 0V7H8.5a1 1 0 000 2zm15 15.5h-1v-1a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2zm-.29-9.29L9.91 28.5a1.99 1.99 0 01-2.82 0L4.5 25.91a1.99 1.99 0 010-2.82L23.09 4.5a2.01 2.01 0 012.82 0l2.59 2.59a1.99 1.99 0 010 2.82l-5.29 5.3zm-3.3-4.71l2.59 2.59 4.59-4.59-2.59-2.59-4.59 4.59z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'plus',
        icon: [
            18,
            18,
            [],
            'e052',
            'M17.92 7.34a.96.96 0 00-.96-.86h-5.44V1.04c0-.5-.38-.91-.86-.96-1.1-.1-2.22-.1-3.32 0a.96.96 0 00-.86.96v5.44H1.04c-.5 0-.91.38-.96.86-.1 1.1-.1 2.22 0 3.32.05.49.47.86.96.86h5.44v5.44c0 .5.37.91.86.96 1.1.1 2.21.1 3.32 0a.96.96 0 00.86-.96v-5.44h5.44c.5 0 .91-.37.96-.86.1-1.1.1-2.21 0-3.32z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'purchase',
        icon: [
            21,
            20,
            [],
            'e053',
            'M15.57 8.05c-1.23 1.86-3.36 3.18-3.44 3.25a.46.46 0 01-.58 0c-.08-.06-2.21-1.39-3.45-3.25-.2-.31.02-.73.39-.73h1.81c.17-1.02.45-2.14.6-2.41.16-.26.47-.42.94-.42.46 0 .77.16.93.42.13.23.43 1.39.6 2.41h1.82c.37 0 .59.42.38.73zm3.18-5.33H6.14C5.74 1.12 4.5.08 2.95.08H1.64a1.32 1.32 0 100 2.64h1.31c.48 0 .63.52.68.84.48 3.41 1.56 9.84 1.57 9.9.1.64.66 1.1 1.3 1.1 6.34 0 11-.97 11.2-1.02.54-.1.94-.54 1.03-1.08l1.32-8.22c.12-.8-.5-1.52-1.3-1.52zM16.1 15.88a1.98 1.98 0 100 3.95 1.98 1.98 0 000-3.95zm-9.2 0a1.98 1.98 0 100 3.95 1.98 1.98 0 000-3.95z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'question',
        icon: [
            40,
            40,
            [],
            'e054',
            'M20 .5a19.5 19.5 0 100 39 19.5 19.5 0 000-39zM20 32a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5zm1.5-9.17V23a1.5 1.5 0 11-3 0v-1.5A1.5 1.5 0 0120 20a3.75 3.75 0 10-3.75-3.75 1.5 1.5 0 11-3 0 6.75 6.75 0 118.25 6.58z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'reject-call',
        icon: [
            36,
            36,
            [],
            'e055',
            'M31.22 22.28l-6.6-2.82a2.2 2.2 0 00-2.12.19l-3.53 2.34a10.76 10.76 0 01-4.95-4.92l2.33-3.57a2.24 2.24 0 00.2-2.12l-2.83-6.6a2.3 2.3 0 00-2.34-1.35 7.9 7.9 0 00-6.88 7.82A20.27 20.27 0 0024.75 31.5a7.9 7.9 0 007.82-6.88 2.3 2.3 0 00-1.35-2.34z M21.7 14.3a1.15 1.15 0 001.6 0l2.58-2.59 2.57 2.6a1.15 1.15 0 001.6 0 1.14 1.14 0 000-1.61l-2.59-2.57 2.6-2.58a1.14 1.14 0 00-1.61-1.6l-2.57 2.59-2.58-2.6a1.14 1.14 0 00-1.6 1.61l2.59 2.58-2.6 2.57a1.14 1.14 0 000 1.6z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'save',
        icon: [
            36,
            36,
            [],
            'e056',
            'M10.45 12.05a1.14 1.14 0 011.6-1.6l4.82 4.84V3.38a1.12 1.12 0 112.25 0v11.9l4.83-4.83a1.14 1.14 0 011.6 1.6L18.8 18.8a1.15 1.15 0 01-1.6 0l-6.75-6.75zm23.3 7.07v9a2.25 2.25 0 01-2.25 2.25h-27a2.25 2.25 0 01-2.25-2.25v-9a2.25 2.25 0 012.25-2.25h7.37a.5.5 0 01.4.17l3.34 3.35a3.39 3.39 0 004.78 0l3.35-3.35a.5.5 0 01.4-.16h7.36a2.25 2.25 0 012.25 2.25z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'search-minus',
        icon: [
            24,
            24,
            [],
            'e057',
            'M23.11 21.88l-4.72-4.73a10.08 10.08 0 10-1.24 1.24l4.73 4.73a.88.88 0 101.24-1.25zM14.2 11.56h-7a.87.87 0 010-1.75h7a.88.88 0 110 1.75z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'search-plus',
        icon: [
            24,
            24,
            [],
            'e058',
            'M23.11 21.88l-4.72-4.73a10.08 10.08 0 10-1.24 1.24l4.73 4.73a.88.88 0 101.24-1.25zM14.2 11.56h-2.63v2.63a.87.87 0 11-1.75 0v-2.63H7.2a.87.87 0 010-1.75h2.6V7.2a.88.88 0 111.75 0v2.6h2.63a.88.88 0 110 1.75z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'search',
        icon: [
            18,
            18,
            [],
            'e059',
            'M10.47 12.74a5.22 5.22 0 110-10.43 5.22 5.22 0 010 10.43zm0-12.74a7.53 7.53 0 00-6.82 10.68l-3.3 4.43a1.81 1.81 0 002.54 2.53l4.43-3.29A7.53 7.53 0 1010.48 0z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'share-link',
        icon: [
            29,
            24,
            [],
            'e060',
            'M28.02 8.24l-6.36 6.36a1.1 1.1 0 01-1.16.23 1.06 1.06 0 01-.65-.98v-5.3a11.64 11.64 0 00-11.28 8.74 1.07 1.07 0 01-1.04.8l-.26-.05a1.06 1.06 0 01-.76-1.28A13.76 13.76 0 0119.85 6.43v-5.3a1.06 1.06 0 01.65-.98 1.11 1.11 0 011.16.23l6.36 6.35a1.07 1.07 0 010 1.51zm-5 13.02H2.9V5.36a1.06 1.06 0 10-2.12 0v15.9a2.12 2.12 0 002.12 2.12h20.13a1.06 1.06 0 100-2.12z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'share',
        icon: [
            512,
            512,
            [],
            'e061',
            'M353.1 314.6c-20.7 0-39.7 7.2-54.7 19.1l-93.8-58.6c2.8-12.6 2.8-25.6 0-38.1l93.8-58.6c15 12 34 19.1 54.7 19.1 48.5 0 87.9-39.3 87.9-87.9s-39.3-87.9-87.9-87.9a87.83 87.83 0 00-85.8 107l-93.8 58.6c-15-12-34-19.1-54.7-19.1C70.3 168.1 31 207.5 31 256a87.9 87.9 0 00142.6 68.8l93.8 58.6a87.9 87.9 0 0085.8 107c48.6 0 87.8-39.4 87.8-88s-39.3-87.8-87.9-87.8z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'spinner-third',
        icon: [
            512,
            512,
            [],
            'e062',
            'M460.12 373.85l-20.83-12.03a12.03 12.03 0 01-4.66-15.87c30.14-59.89 28.34-131.65-5.39-189.95-33.64-58.39-94.9-95.83-161.82-99.67A12.03 12.03 0 01256 44.35V20.31c0-6.9 5.8-12.34 12.7-11.98 83.56 4.3 160.17 50.86 202.11 123.67 42.07 72.7 44.08 162.32 6.03 236.84a12.03 12.03 0 01-16.72 5z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'star-light',
        icon: [
            576,
            512,
            [],
            'e063',
            'M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'star',
        icon: [
            576,
            512,
            [],
            'e064',
            'M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'sticker-checked',
        icon: [
            32,
            32,
            [],
            'e065',
            'M28.24 12.85c-.48-.49-.96-1-1.15-1.44-.19-.44-.18-1.09-.19-1.75-.01-1.21-.04-2.6-1-3.56s-2.35-.99-3.56-1c-.66-.01-1.34-.02-1.75-.19a5.7 5.7 0 01-1.44-1.15C18.29 2.94 17.3 2 16 2c-1.3 0-2.29.94-3.15 1.76-.49.48-1 .96-1.44 1.15-.44.19-1.09.18-1.75.19-1.21.01-2.6.04-3.56 1s-.99 2.35-1 3.56c-.01.66-.02 1.34-.19 1.75a5.7 5.7 0 01-1.15 1.44C2.94 13.71 2 14.7 2 16c0 1.3.94 2.29 1.76 3.15.48.49.96 1 1.15 1.44.19.43.18 1.09.19 1.75.01 1.21.04 2.6 1 3.56s2.35.99 3.56 1c.66.01 1.34.03 1.75.19.41.16.95.67 1.44 1.15.86.82 1.85 1.76 3.15 1.76 1.3 0 2.29-.94 3.15-1.76.49-.48 1-.96 1.44-1.15.43-.19 1.09-.18 1.75-.19 1.21-.01 2.6-.04 3.56-1s.99-2.35 1-3.56c.01-.66.03-1.34.19-1.75a5.7 5.7 0 011.15-1.44C29.06 18.29 30 17.3 30 16c0-1.3-.94-2.29-1.76-3.15zm-6.05.88l-7.33 7a1.01 1.01 0 01-1.38 0l-3.67-3.5a1 1 0 111.38-1.46l2.97 2.84 6.65-6.33a1 1 0 011.38 1.45z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'switch',
        icon: [
            22,
            22,
            [],
            'e066',
            'M4.3 21.7l-4-4A1 1 0 011 16h3V1a1 1 0 112 0v15h3a1 1 0 01.7 1.7l-4 4a1 1 0 01-1.4 0zm8-17.4A1 1 0 0013 6h3v15a1 1 0 102 0V6h3a1 1 0 00.7-1.7l-4-4a1 1 0 00-1.4 0l-4 4z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'times-alt',
        icon: [
            390,
            512,
            [],
            'e067',
            'M377.3 392.4L240.5 255.7 377.3 119c12.6-12.6 12.6-33 0-45.6a32.23 32.23 0 00-45.6 0L195 210.2 58.3 73.4a32.23 32.23 0 00-45.6 0 32.23 32.23 0 000 45.6l136.8 136.7L12.7 392.5l-1.2 1.2a32.33 32.33 0 001.2 45.6 32.33 32.33 0 0045.6-1.2L195 301.3 331.7 438l1.2 1.2a32.33 32.33 0 0045.6-1.2 32.33 32.33 0 00-1.2-45.6z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'times-shade-purple',
        icon: [
            15,
            14,
            [],
            'e068',
            'M10.08 7l3.77-3.77c.32-.32.38-.82.13-1.13A12.3 12.3 0 0012.03.16c-.3-.26-.8-.2-1.13.13l-3.77 3.6L3.36.3c-.32-.34-.81-.4-1.13-.14C1.52.73.87 1.38.3 2.1c-.25.31-.2.8.13 1.13L4.2 7 .42 10.77C.1 11.1.04 11.6.3 11.9c.58.72 1.23 1.37 1.94 1.94.32.26.81.2 1.14-.13l3.76-3.77 3.77 3.77c.33.33.82.38 1.13.13.72-.57 1.37-1.22 1.95-1.94.25-.31.2-.8-.13-1.13L10.08 7z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'times',
        icon: [
            18,
            18,
            [],
            'e069',
            'M12.78 9l4.85-4.84c.42-.42.5-1.06.17-1.46A15.82 15.82 0 0015.3.2c-.4-.32-1.03-.25-1.46.17L9 5.01 4.15.37C3.73-.05 3.1-.13 2.7.2 1.78.94.94 1.78.2 2.7c-.32.4-.25 1.04.17 1.45L5.22 9 .37 13.85c-.42.42-.5 1.05-.17 1.45a16.3 16.3 0 002.5 2.5c.4.32 1.03.25 1.46-.17L9 12.78l4.84 4.85c.43.42 1.06.49 1.46.17.92-.74 1.76-1.57 2.5-2.5.32-.4.25-1.03-.17-1.46L12.78 9z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'trash-can',
        icon: [
            12,
            13,
            [],
            'e070',
            'M11.5 2H9v-.5A1.5 1.5 0 007.5 0h-3A1.5 1.5 0 003 1.5V2H.5a.5.5 0 000 1H1v9a1 1 0 001 1h8a1 1 0 001-1V3h.5a.5.5 0 000-1zM5 9.5a.5.5 0 11-1 0v-4a.5.5 0 111 0v4zm3 0a.5.5 0 11-1 0v-4a.5.5 0 111 0v4zM8 2H4v-.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V2z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'trash',
        icon: [
            390,
            512,
            [],
            'e071',
            'M296 432h16a8 8 0 008-8V152a8 8 0 00-8-8h-16a8 8 0 00-8 8v272a8 8 0 008 8zm-160 0h16a8 8 0 008-8V152a8 8 0 00-8-8h-16a8 8 0 00-8 8v272a8 8 0 008 8zM440 64H336l-33.6-44.8A48 48 0 00264 0h-80a48 48 0 00-38.4 19.2L112 64H8a8 8 0 00-8 8v16a8 8 0 008 8h24v368a48 48 0 0048 48h288a48 48 0 0048-48V96h24a8 8 0 008-8V72a8 8 0 00-8-8zM171.2 38.4A16.1 16.1 0 01184 32h80a16.1 16.1 0 0112.8 6.4L296 64H152zM384 464a16 16 0 01-16 16H80a16 16 0 01-16-16V96h320zm-168-32h16a8 8 0 008-8V152a8 8 0 00-8-8h-16a8 8 0 00-8 8v272a8 8 0 008 8z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'trophy',
        icon: [
            31,
            24,
            [],
            'e072',
            'M29 3h-3V2a2 2 0 00-2-2H8a2 2 0 00-2 2v1H2.99a2 2 0 00-2 2v2a5 5 0 005 5h.5A10.1 10.1 0 0015 18.95V22h-3a1 1 0 000 2h8a1 1 0 000-2h-3v-3.05A9.96 9.96 0 0025.54 12H26a5 5 0 005-5V5a2 2 0 00-2-2zM5.99 10a3 3 0 01-3-3V5H6v3.89c0 .37.02.74.06 1.11H6zM29 7a3.01 3.01 0 01-3 3h-.05c.04-.33.05-.67.05-1V5h3v2z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'upload',
        icon: [
            512,
            512,
            [],
            'e073',
            'M452 432c0 11-9 20-20 20s-20-9-20-20 9-20 20-20 20 9 20 20zm-84-20c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm144-48v104a44 44 0 01-44 44H44a44 44 0 01-44-44V364a44 44 0 0144-44h124v-99.3h-52.7c-35.6 0-53.4-43.1-28.3-68.3L227.7 11.7a40.08 40.08 0 0156.6 0L425 152.4c25.2 25.2 7.3 68.3-28.3 68.3H344V320h124a44 44 0 0144 44zM200 188.7V376c0 4.4 3.6 8 8 8h96c4.4 0 8-3.6 8-8V188.7h84.7c7.1 0 10.7-8.6 5.7-13.7L261.7 34.3a8.03 8.03 0 00-11.3 0L109.7 175c-5 5-1.5 13.7 5.7 13.7H200zM480 364c0-6.6-5.4-12-12-12H344v24c0 22.1-17.9 40-40 40h-96c-22.1 0-40-17.9-40-40v-24H44c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12V364z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'uploaded',
        icon: [
            21,
            20,
            [],
            'e074',
            'M17.3 10.14c0 2.8-.22 4.77-.4 5.92H4.37a39.1 39.1 0 01-.4-5.92H.77c0 5.3.7 7.9.74 8.01.2.7.84 1.2 1.58 1.2h15.08c.74 0 1.39-.5 1.59-1.2.03-.11.74-2.75.74-8.01h-3.2zM5.42 7.49H8.1c.28 1.67.74 3.49 1 3.94.26.42.77.67 1.53.67.77 0 1.28-.25 1.53-.67.23-.4.72-2.27 1-3.94h2.67c.61 0 .98-.68.64-1.19A33.25 33.25 0 0011.1.43a.77.77 0 00-.95 0c-.14.1-3.32 2.83-5.36 5.87-.34.5.02 1.2.63 1.2z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'user-friends',
        icon: [
            24,
            21,
            [],
            'e075',
            'M13.35 14.8c-1.98-.77-2.64-1.18-1.45-2.95 1.63-2.52 1.92-4.7.85-6.47A4.54 4.54 0 008.8 3.2c-1.64 0-3.1.82-3.93 2.18-1.07 1.77-.79 3.95.85 6.48 1.19 1.76.51 2.17-1.45 2.95-2.54 1-3.84 2.65-4.26 4.56-.1.44.2.86.63.93.08.01 2.3.32 8.16.32 5.87 0 8.1-.3 8.18-.32a.77.77 0 00.63-.93c-.42-1.9-1.72-3.55-4.27-4.56zm10.63 1.37c-.42-1.9-1.72-3.55-4.27-4.56-1.97-.78-2.64-1.19-1.44-2.96 1.63-2.52 1.91-4.7.84-6.47A4.54 4.54 0 0015.17 0c-1.46 0-2.26.65-3.11 1.77 2.53 1.12 5.4 5.03 1.62 11.01.8.34 3.82 1.25 5.36 4.59 3.01-.1 4.25-.26 4.31-.27a.77.77 0 00.63-.93z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'user',
        icon: [
            40,
            38,
            [],
            'e076',
            'M39.48 35.75A22.63 22.63 0 0026.9 25.59a13.5 13.5 0 10-13.8 0A22.63 22.63 0 00.52 35.75a1.44 1.44 0 000 1.5 1.46 1.46 0 001.3.75h36.37a1.46 1.46 0 001.3-.75 1.44 1.44 0 000-1.5z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'vertical-ellipsis',
        icon: [
            24,
            24,
            [],
            'e077',
            'M18 11.25H6a.75.75 0 100 1.5h12a.75.75 0 100-1.5z M21.75 6.75H2.25a.75.75 0 000 1.5h19.5a.75.75 0 100-1.5z M14.25 15.75h-4.5a.75.75 0 100 1.5h4.5a.75.75 0 100-1.5z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'video-camera',
        icon: [
            512,
            512,
            [],
            'e078',
            'M475.8 444.1v.3L360.6 329.3v73c0 23.1-18.7 41.8-41.8 41.8h-272C23.7 444.1 5 425.4 5 402.3V109.4c0-23.1 18.7-41.8 41.8-41.8h271.9c23.1 0 41.8 18.7 41.8 41.8v73.5l115-115v-.3c17.3 0 31.4 14 31.4 31.4v313.8c.1 17.2-13.9 31.2-31.1 31.3z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'video-close',
        icon: [
            36,
            36,
            [],
            'e079',
            'M32.33 31.87a1.11 1.11 0 01-.07 1.58 1.12 1.12 0 01-1.59-.07l-5.96-6.55a2.24 2.24 0 01-2.21 1.86H3.37a2.25 2.25 0 01-2.25-2.25V9.56a2.25 2.25 0 012.25-2.25h3.6l-2.9-3.18a1.13 1.13 0 011.67-1.51l26.59 29.25zm2.2-21.44a1.11 1.11 0 00-1.34-.15l-5.63 3.2a1.13 1.13 0 00-.56.99v7.06a1.13 1.13 0 00.56.98l5.63 3.21a1.11 1.11 0 001.38-.2c.2-.21.31-.5.3-.8V11.28a1.13 1.13 0 00-.35-.85zm-11.73 7.6a1.13 1.13 0 001.37.22 1.15 1.15 0 00.58-1.02V9.56a2.25 2.25 0 00-2.25-2.25h-6.88a1.2 1.2 0 00-.92.44 1.12 1.12 0 00.07 1.45l8.03 8.83z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'video-open',
        icon: [
            36,
            36,
            [],
            'e080',
            'M24.75 12.94v13.5a2.25 2.25 0 01-2.25 2.25H6.75a5.62 5.62 0 01-5.63-5.63V9.56a2.25 2.25 0 012.25-2.25h15.75a5.63 5.63 0 015.63 5.63zm9.56-2.66a1.08 1.08 0 00-1.12 0l-5.63 3.2a1.12 1.12 0 00-.56.99v7.06a1.12 1.12 0 00.56.98l5.63 3.21c.17.1.36.15.56.16.2 0 .4-.06.56-.16a1.1 1.1 0 00.56-.97v-13.5a1.1 1.1 0 00-.56-.97z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'warning',
        icon: [
            28,
            26,
            [],
            'e081',
            'M27.59 21.5L16.6 2.5a3 3 0 00-5.2 0L.41 21.5A2.99 2.99 0 003 26h22a2.99 2.99 0 002.59-4.5zM13 11a1 1 0 012 0v5a1 1 0 01-2 0v-5zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z',
        ],
    },
    {
        prefix: 'tg',
        iconName: 'xmark',
        icon: [
            13,
            13,
            [],
            'e082',
            'M1.7.3A1 1 0 00.3 1.7l4.4 4.41-3.96 3.96a1 1 0 101.41 1.41l3.96-3.95 4.4 4.4a1 1 0 101.42-1.41l-4.4-4.4 4.4-4.41A1 1 0 1010.52.29l-4.4 4.4L1.7.3z',
        ],
    },
];
