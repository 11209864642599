import { IVideoStream } from './video-stream.interface';
import { ActivityEvent, ActivityEventDeliveryMode, UpdateCallLatencyInteractor, CallMetadata } from '@together/common';
import { AbstractLogger } from '@mobilejazz/harmony-core';

export enum CallDataTrackMonitorConstants {
    originActivityId = 't.hb', // Together heart beat
    heartBeatEvent = 'b', // Heart beat
    heartBeatResponseEvent = 'br',
}

export interface CallLatency {
    numberOfLatencyRecordings?: number;
    sumOfLatency?: number;
    highestLatencyValue?: number;
    timesLatencyAboveHalfSecond?: number;
    timesLatencyAboveSecond?: number;
}

export class CallDataTrackMonitor {
    private intervalId;
    private heartbeatInterval: number = 3000; //3 seconds
    private callLatency: CallLatency;

    constructor(
        protected logger: AbstractLogger,
        protected callMetadata: CallMetadata,
        protected stream: IVideoStream,
        protected updateCallLatencyInteractor: UpdateCallLatencyInteractor,
    ) {
        this.callLatency = {
            numberOfLatencyRecordings: 0,
            sumOfLatency: 0,
            highestLatencyValue: 0,
            timesLatencyAboveHalfSecond: 0,
            timesLatencyAboveSecond: 0,
        };
    }

    public startMonitoring() {
        this.logger.info('DT MONITOR', `Started monitoring`);
        this.intervalId = setInterval(() => {
            this.sendHearBeat();
        }, this.heartbeatInterval);
    }

    public stopMonitoring() {
        this.logger.info('DT MONITOR', `Stopped monitoring`);
        clearInterval(this.intervalId);
    }

    public processHeartbeatEvent(event: ActivityEvent) {
        this.logger.info('DT MONITOR', `Received Heartbeat event to process`);
        if (event.name === CallDataTrackMonitorConstants.heartBeatEvent) {
            this.sendHearBeatResponseFor(event);
        } else if (event.name === CallDataTrackMonitorConstants.heartBeatResponseEvent) {
            // We got an event back
            this.processHeartBeatResponse(event);
        }
    }

    public async uploadLatencyData() {
        this.logger.info('DT MONITOR', `Upload Latency Data`);
        // We only record if we have enough data
        if (this.callLatency.numberOfLatencyRecordings > 5) {
            const latencyPayload = {
                latencyAverage: this.callLatency.sumOfLatency / this.callLatency.numberOfLatencyRecordings,
                latencyRecordings: this.callLatency.numberOfLatencyRecordings,
                latencyHighestValue: this.callLatency.highestLatencyValue,
                latencyRecsAboveHalfSecond: this.callLatency.timesLatencyAboveHalfSecond,
            };
            await this.updateCallLatencyInteractor.execute(this.callMetadata.callId, latencyPayload);
        }
    }

    private sendHearBeat() {
        this.logger.info('DT MONITOR', 'Send Heartbeat');
        const time = new Date().getTime() / 1000;
        let heartBeatEvent: ActivityEvent = {
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: CallDataTrackMonitorConstants.originActivityId,
            name: CallDataTrackMonitorConstants.heartBeatEvent,
            value: time.toString(),
        };
        try {
            this.stream.send(heartBeatEvent);
        } catch (error) {
            this.logger.error('DT MONITOR: Error while sending heart beat: ', error);
        }
    }

    private sendHearBeatResponseFor(event: ActivityEvent) {
        this.logger.info('DT MONITOR', 'Send Heartbeat Response for a Heartbeat from admin');
        let heartBeatResponseEvent: ActivityEvent = {
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: CallDataTrackMonitorConstants.originActivityId,
            name: CallDataTrackMonitorConstants.heartBeatResponseEvent,
            value: event.value,
        };
        try {
            this.stream.send(heartBeatResponseEvent);
        } catch (error) {
            this.logger.error('DT MONITOR: Error while sending heart beat response: ', error);
        }
    }

    private processHeartBeatResponse(event: ActivityEvent) {
        if (event.value) {
            const originalTime = parseFloat(event.value);
            const nowTime = new Date().getTime() / 1000;
            const latency = nowTime - originalTime;
            // TODO: We need to define what is an acceptable latency value
            this.logger.info('DT MONITOR', `Latency: ${latency}`);

            this.callLatency.numberOfLatencyRecordings += 1;
            this.callLatency.sumOfLatency += latency;
            if (latency > this.callLatency.highestLatencyValue) {
                this.callLatency.highestLatencyValue = latency;
            }
            if (latency > 0.5) {
                this.callLatency.timesLatencyAboveHalfSecond += 1;
            }

            if (latency > 1) {
                this.callLatency.timesLatencyAboveSecond += 1;
            }
        }
    }
}
