import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BookInfoModalComponent } from '@app/web/modals/book-info-modal/book-info-modal.component';

import { BookModel, GetPlatformInteractor, UserService } from '@together/common';
import { DateTime, Interval } from 'luxon';
import { SimpleModalService } from '@looorent/ngx-simple-modal';

@Component({
    selector: 'app-featured-book-thumbnail',
    templateUrl: './featured-book-thumbnail.component.html',
    styleUrls: ['./featured-book-thumbnail.component.scss'],
})
export class FeaturedBookThumbnailComponent implements OnInit {
    @Input() book: BookModel;
    @Input() isFav = false;
    @Input() allowPurchase = false;

    @Output() public toggleFavorite = new EventEmitter<BookModel>();
    @Output() public selected = new EventEmitter<BookModel>();

    public isLoggedIn = false;
    private isWeb: boolean;

    constructor(
        protected modalService: SimpleModalService,
        protected userService: UserService,
        protected getPlatform: GetPlatformInteractor,
    ) {}

    async ngOnInit() {
        this.isLoggedIn = await this.userService.isLoggedIn();
        this.isWeb = this.getPlatform.isWeb();
    }

    public purchaseAction() {
        if (this.isWeb) {
            this.modalService.addModal(BookInfoModalComponent, {
                book: this.book,
                purchase: this.book.purchaseDetails,
                isLoggedIn: this.isLoggedIn,
                allowPurchase: this.allowPurchase,
            });
        }
    }

    public getRentalDaysLeft(): string {
        if (this.book?.purchaseDetails?.rentalStartDate) {
            const rentalEndDate = DateTime.fromJSDate(this.book.purchaseDetails.rentalStartDate).plus({
                days: this.book.purchaseDetails.rentalDuration,
            });
            const i = Interval.fromDateTimes(DateTime.now(), rentalEndDate);
            return i.length('days').toFixed(0);
        }
        return null;
    }
}
