import { Share } from '@capacitor/share';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WaitModalService } from '@app/shared/services/wait-modal.service';
import { Platform } from '@ionic/angular';
import {
    AnalyticsEvent,
    CopyToClipboardInteractor,
    GenerateInviteCodeInteractor,
    GetInviteByCodeInteractor,
    LogAnalyticsEventInteractor,
    SendInviteInteractor,
    UserIsAlreadyContact,
    UserModel,
    UserService,
} from '@together/common';
import { AbstractLogger, NotFoundError } from '@mobilejazz/harmony-core';
import { SimpleModalComponent, SimpleModalService } from '@looorent/ngx-simple-modal';
import { AcceptInviteModalComponent } from '../accept-invite-modal/accept-invite-modal.component';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

import { createShareOptions } from './invitation';
import { ContactsService } from '@app/shared/services/contacts.service';
import { BehaviorSubject, first, firstValueFrom } from 'rxjs';

export interface InviteModalComponentData {
    /** Set this to true to signal the component that it was opened as part of the sign-up flow */
    isSignUpFlow: boolean;
}

@Component({
    selector: 'app-invite-modal',
    templateUrl: './invite-modal.component.html',
    styleUrls: ['./invite-modal.component.scss'],
})
export class InviteModalComponent
    extends SimpleModalComponent<InviteModalComponentData, void>
    implements OnInit, OnDestroy
{
    public isAndroid = false;
    public isInviteCodeFormVisible = false;
    public isSignUpFlow = false;
    public emailForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
    });
    public codeForm = this.fb.group({
        shortInviteCode: ['', [Validators.required]],
    });

    /** Variable to keep track if the user skipped redeeming/inviting on the sign-up flow */
    private didSkipOnSignUp = true;

    constructor(
        protected copyToClipboard: CopyToClipboardInteractor,
        protected fb: UntypedFormBuilder,
        protected generateInviteCode: GenerateInviteCodeInteractor,
        protected getInviteByCode: GetInviteByCodeInteractor,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected logger: AbstractLogger,
        protected modalService: SimpleModalService,
        protected platform: Platform,
        protected sendInviteInteractor: SendInviteInteractor,
        protected userService: UserService,
        protected waitModal: WaitModalService,
        protected contactsService: ContactsService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isAndroid = this.platform.is('android');
    }

    public ngOnDestroy(): void {
        if (this.isSignUpFlow && this.didSkipOnSignUp) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.RegistrationSkippedInvite);
        }
    }

    public async enterInviteCode(): Promise<void> {
        const shortInviteCode = this.codeForm.get('shortInviteCode').value;

        this.waitModal.show();

        try {
            const invite = await this.getInviteByCode.execute(shortInviteCode);
            const user = await this.userService.getUser();
            if (user.id === invite.creator.id) {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: `You can't add yourself as a contact. Share this code with someone you want to invite.`,
                    type: 'warning',
                    icon: 'exclamation',
                });
                this.waitModal.hide();
                return;
            }
            this.close();
            this.modalService.addModal(AcceptInviteModalComponent, { invite });

            if (this.isSignUpFlow) {
                this.logAnalyticsEvent.execute(AnalyticsEvent.RegistrationInviteCodeRedeemed);
                this.didSkipOnSignUp = false;
            }
        } catch (err) {
            if (err instanceof NotFoundError) {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: `We couldn't find that invite. Double-check the code and try again.`,
                    type: 'warning',
                    icon: 'exclamation',
                });
            }
        } finally {
            this.waitModal.hide();
        }
    }

    public isControlInvalid(form: UntypedFormGroup, field: string): boolean {
        return form.get(field).invalid && form.get(field).touched;
    }

    public async sendInvite(): Promise<void> {
        this.emailForm.markAllAsTouched();
        const user = await this.userService.getUser();
        this.logger.info('InviteModal', `Send invite`);

        if (this.emailForm.invalid) {
            this.logger.info('InviteModal', `Send invite form is invalid`);
            return;
        }

        if (this.emailForm.get('email').value === user.email) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: `You can’t add yourself as a contact. Try another email.`,
                type: 'warning',
                icon: 'exclamation',
            });
            return;
        }

        this.waitModal.show();
        //Update contacts list to prevent invites being sent again to already accepted invites
        const contactsSubject = new BehaviorSubject<UserModel[]>(null);
        this.contactsService.getContact$().subscribe(contactsSubject);
        const contacts = await firstValueFrom(contactsSubject.pipe(first()));
        user.contacts = contacts.map(c => c.id);

        const email = this.emailForm.get('email').value;

        try {
            const invite = await this.sendInviteInteractor.execute(user, email);

            this.modalService.addModal(AlertModalComponent, {
                title: 'Great!',
                message: 'Once they accept your invitation, you will see them in your contact list.',
                type: 'success',
                icon: 'checked-round',
            });

            this.close();
            this.logger.info('InviteModal', `Invite sent: ${JSON.stringify(invite, null, 4)}`);

            if (this.isSignUpFlow) {
                this.logAnalyticsEvent.execute(AnalyticsEvent.RegistrationSentInvite);
                this.didSkipOnSignUp = false;
            }
        } catch (err) {
            if (err instanceof UserIsAlreadyContact) {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: `You're already connected with ${email}.`,
                    type: 'warning',
                    icon: 'exclamation',
                });
            } else {
                throw err;
            }
        } finally {
            this.waitModal.hide();
        }
    }

    public async copyInvite(): Promise<void> {
        this.waitModal.show();
        const user = await this.userService.getUser();
        const invite = await this.generateInviteCode.execute(user);
        const shareOptions = createShareOptions(invite);
        this.copyToClipboard.execute(shareOptions.text);
        this.waitModal.hide();

        this.modalService.addModal(AlertModalComponent, {
            title: 'Done!',
            message: `The invitation message has been copied to your clipboard.`,
            type: 'success',
            icon: 'checked-round',
        });
    }

    public async shareInvite(): Promise<void> {
        this.waitModal.show();

        try {
            const user = await this.userService.getUser();
            const invite = await this.generateInviteCode.execute(user);

            await Share.share(createShareOptions(invite));

            setTimeout(() => {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Great!',
                    message: 'Once they accept your invite you will see them in your contact list',
                    type: 'success',
                    icon: 'checked-round',
                });
            }, 1500);

            this.logger.info('InviteModal', `Shared invite with code: ${invite.shortCode}`);
        } catch (err) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Oops!',
                message: 'There was an error while sharing your invite. Please try again.',
                type: 'error',
                icon: 'error',
            });
        } finally {
            this.waitModal.hide();
        }
    }

    public toggleInviteCodeForm(): void {
        this.isInviteCodeFormVisible = !this.isInviteCodeFormVisible;
    }
}
