import { AbstractLogger } from '@mobilejazz/harmony-core';
import { Subject } from 'rxjs';

import {
    ActivityEvent,
    ActivityEventDeliveryMode,
    CallMetadata,
    FaceFilterModel,
    PhotoboothImageModel,
    ProcessPhotoboothImageInteractor,
    GetAllPhotoboothImagesInteractor,
    DeletePhotoboothImagesInteractor,
    LogAnalyticsEventInteractor,
    AnalyticsEvent,
} from '@together/common';
import { CallEvent } from './machines/call.machine';
import { IVideoStream } from './video-stream.interface';

export enum PhotoboothEvent {
    OriginActivityId = 't.photobooth', // Together photobooth event
    ShowPhotobooth = 'ShowPhotoboothEvent',
    ClosePhotobooth = 'ClosePhotoboothEvent',
    FlipFaceFilters = 'FlipFaceFiltersEvent',
    ApplyFilterRequestedEvent = 'ApplyFilterRequestedEvent',
    ApplyFilterResponseEvent = 'ApplyFilterResponseEvent',
    TakePhotoBoothPhoto = 'TakePhotoEvent',
    RequestPhotobooth = 'RequestPhotoboothEvent',
}

export class Photobooth {
    event$: Subject<CallEvent>;
    public showGallery = false;
    public showRemovePhoto = false;
    public photoboothMode: string = 'local';
    public showFilterLoadingState: boolean;
    public selectedFaceFilterId: string;

    constructor(
        protected logger: AbstractLogger,
        protected stream: IVideoStream,
        protected metadata: CallMetadata,
        protected processPhotoboothImageInteractor: ProcessPhotoboothImageInteractor,
        protected getPhotoboothImageInteractor: GetAllPhotoboothImagesInteractor,
        protected deletePhotoboothImageInteractor: DeletePhotoboothImagesInteractor,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        public faceFilters: FaceFilterModel[],
        public photoboothImages: PhotoboothImageModel[],
    ) {
        this.event$ = new Subject<CallEvent>();
    }

    handleEvent(event: ActivityEvent) {
        switch (event.name) {
            case PhotoboothEvent.ShowPhotobooth:
                this.logger.info('Photobooth:Event OpenObserveLoggerTag', `Show Photobooth`);
                this.event$.next({ type: 'SHOW_PHOTOBOOTH', notifyRemote: false });
                break;

            case PhotoboothEvent.ClosePhotobooth:
                this.logger.info('Photobooth:Event OpenObserveLoggerTag', `Close Photobooth`);
                this.hidePhotoboothGallery();
                this.event$.next({ type: 'BACK', notifyRemote: false });
                break;

            case PhotoboothEvent.FlipFaceFilters:
                this.logger.info('Photobooth:Event OpenObserveLoggerTag', `Remote flipped the face filter mode`);
                this.flipFaceFilterMode();
                this.event$.next({ type: 'FLIP_FACE_FILTERS', notifyRemote: false });
                break;

            case PhotoboothEvent.TakePhotoBoothPhoto:
                this.logger.info('Photobooth:Event OpenObserveLoggerTag', `Take photo triggered by the remote`);
                this.event$.next({ type: 'TAKE_PHOTO', notifyRemote: false });
                break;

            case PhotoboothEvent.ApplyFilterRequestedEvent:
                this.logger.info(
                    'Photobooth:Event OpenObserveLoggerTag',
                    `Apply filter request from remote, ${event.value}`,
                );
                if (event.value) {
                    const filterId = event.value;
                    if (filterId === 'noneId') {
                        this.applyFaceFilter({ id: 'noneId' }, true);
                        return;
                    }
                    const filter = this.faceFilters.find(f => f.id === filterId);
                    if (filter) {
                        this.applyFaceFilter(filter, true);
                    }
                }
                break;

            case PhotoboothEvent.ApplyFilterResponseEvent:
                this.logger.info(
                    'Photobooth:Event OpenObserveLoggerTag',
                    `Apply filter response from remote, ${event.value}`,
                );
                if (event.value) {
                    this.showFilterLoadingState = false;
                }
                break;

            case PhotoboothEvent.RequestPhotobooth:
                this.logger.info('Photobooth:Event OpenObserveLoggerTag', `Photobooth requested by the remote`);
                this.event$.next({ type: 'ANIMATE_PHOTOBOOTH' });
                break;
        }
    }

    public initialisePhotobooth(notifyRemote?) {
        this.showFilterLoadingState = false;
        this.selectedFaceFilterId = null;
        if (notifyRemote) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.PhotoboothStart, {
                subscription_status: this.metadata.localParticipant.analyticsType,
            });
            this.stream.send({
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                originActivityId: PhotoboothEvent.OriginActivityId,
                name: PhotoboothEvent.ShowPhotobooth,
                value: '',
            });
        }
        this.stream.startPhotobooth();
    }

    public notifyRequestPhotobooth() {
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: PhotoboothEvent.OriginActivityId,
            name: PhotoboothEvent.RequestPhotobooth,
            value: '',
        });
    }

    public closePhotobooth(notifyRemote?: boolean) {
        this.showFilterLoadingState = false;
        this.stream.clearFaceFilter();
        if (notifyRemote) {
            this.stream.send({
                deliveryMode: ActivityEventDeliveryMode.Guaranteed,
                originActivityId: PhotoboothEvent.OriginActivityId,
                name: PhotoboothEvent.ClosePhotobooth,
                value: '',
            });
        }
    }

    public notifyFlipFaceFilters() {
        this.logAnalyticsEvent.execute(AnalyticsEvent.PhotoboothSwitch, {
            subscription_status: this.metadata.localParticipant.analyticsType,
        });
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: PhotoboothEvent.OriginActivityId,
            name: PhotoboothEvent.FlipFaceFilters,
            value: '',
        });
    }

    public notifyApplyFilterRequest(filter: FaceFilterModel) {
        this.showFilterLoadingState = true;
        this.selectedFaceFilterId = filter.id;
        this.logAnalyticsEvent.execute(AnalyticsEvent.PhotoboothFilterSelected, {
            subscription_status: this.metadata.localParticipant.analyticsType,
            filter_name: filter.name,
        });
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: PhotoboothEvent.OriginActivityId,
            name: PhotoboothEvent.ApplyFilterRequestedEvent,
            value: filter.id,
        });
    }

    public notifyApplyFilterResponse(filter: FaceFilterModel) {
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: PhotoboothEvent.OriginActivityId,
            name: PhotoboothEvent.ApplyFilterResponseEvent,
            value: filter.id,
        });
    }

    public notifyTakePhotoBoothPhoto() {
        this.logAnalyticsEvent.execute(AnalyticsEvent.PhotoboothTakePicture, {
            subscription_status: this.metadata.localParticipant.analyticsType,
        });
        this.stream.send({
            deliveryMode: ActivityEventDeliveryMode.Guaranteed,
            originActivityId: PhotoboothEvent.OriginActivityId,
            name: PhotoboothEvent.TakePhotoBoothPhoto,
            value: '',
        });
    }

    public async createPhotoBoothImage() {
        this.event$.next({ type: 'INCREMENT_PHOTOBOOTH_TIMER' });
        const localStream = this.stream.getLocalVideoEl();
        const remoteStream = this.stream.getRemoteVideoEl();
        await this.processPhotoboothImageInteractor.execute(localStream, remoteStream);
        this.photoboothImages = await this.getPhotoboothImageInteractor.execute();
        this.event$.next({ type: 'SHOW_PHOTOBOOTH_PREVIEW' });
        setTimeout(() => {
            this.showGallery = true;
            this.event$.next({ type: 'SHOW_PHOTOBOOTH_GALLERY' });
        }, 2000);
    }

    public async removePhotoboothImage(image: PhotoboothImageModel) {
        if (image?.id) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.PhotoboothRemovePicture, {
                subscription_status: this.metadata.localParticipant.analyticsType,
            });
            await this.deletePhotoboothImageInteractor.execute([image.id]);
            this.photoboothImages = await this.getPhotoboothImageInteractor.execute();
            this.showRemovePhoto = false;
        }
    }

    public hidePhotoboothGallery() {
        this.showGallery = false;
        this.showRemovePhoto = false;
    }

    public async showPhotoboothGallery() {
        this.photoboothImages = await this.getPhotoboothImageInteractor.execute();
        this.showGallery = true;
    }

    public flipFaceFilterMode() {
        this.selectedFaceFilterId = null;
        this.stream.clearFaceFilter();
        this.hidePhotoboothGallery();
        if (this.photoboothMode === 'local') {
            this.photoboothMode = 'remote';
        } else if (this.photoboothMode === 'remote') {
            this.photoboothMode = 'local';
        }
    }

    public startTakePhotoTimer() {
        this.hidePhotoboothGallery();
        let photoboothTimer = 1;
        const noOfStepsForPhotoboothTimer = 3;
        const intervalId = setInterval(() => {
            if (photoboothTimer === noOfStepsForPhotoboothTimer) {
                this.event$.next({ type: 'INCREMENT_PHOTOBOOTH_TIMER' });
                photoboothTimer++;
                setTimeout(() => {
                    this.createPhotoBoothImage();
                    clearInterval(intervalId);
                }, 300);
            } else {
                this.event$.next({ type: 'INCREMENT_PHOTOBOOTH_TIMER' });
                photoboothTimer++;
            }
        }, 1000);
    }

    public applyFaceFilter(filter: FaceFilterModel, applyingFromRemote = false) {
        if (!applyingFromRemote) {
            this.selectedFaceFilterId = filter.id;
            this.showFilterLoadingState = true;
            this.logAnalyticsEvent.execute(AnalyticsEvent.PhotoboothFilterSelected, {
                subscription_status: this.metadata.localParticipant.analyticsType,
                filter_name: filter.name,
            });
        }
        this.hidePhotoboothGallery();
        this.stream
            .applyFaceFilter(filter)
            .then(() => {
                if (applyingFromRemote) {
                    this.notifyApplyFilterResponse(filter);
                } else {
                    this.showFilterLoadingState = false;
                }
            })
            .catch(e => {
                this.showFilterLoadingState = false;
                this.logger.error(`Error Applying AR Effect: ${filter.name}`);
            });
    }
}
