import { Inject, Injectable } from '@angular/core';
import {
    AnalyticsEvent,
    LogAnalyticsEventInteractor,
    UserService,
    GetCampaignActivationByCodeInteractor,
    PutCampaignActivationInteractor,
    CampaignModel,
    GetCampaignByCodeInteractor,
} from '@together/common';
import { IStoreService } from '../../shared/services/store/store-service.interface';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import { ModalsService } from './modals.service';

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class UpgradeDeepLinkService {
    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected userService: UserService,
        @Inject('StoreService') protected storeService: IStoreService,
        protected putCampaignActivationInteractor: PutCampaignActivationInteractor,
        protected getCampaignActivationInteractor: GetCampaignActivationByCodeInteractor,
        protected logger: AbstractLogger,
        protected getCampaignByCode: GetCampaignByCodeInteractor,
        protected modals: ModalsService,
    ) {}

    async handleURL(url: URL): Promise<void> {
        if (!url.pathname.includes('/upgrade')) {
            return;
        }

        if (!(await this.userService.isLoggedIn())) {
            return;
        }

        const user = await this.userService.getUser();
        //V2.7 Decided to show the upgrade screen with already subscribed message
        // if (user.isSubscribed()) {
        //     return;
        // }
        this.logAnalyticsEvent.execute(AnalyticsEvent.UpgradeLinkOpenedByUser);
        const canShowUpgrade = await this.handleActivationCode(url, user.isSubscribed());
        if (canShowUpgrade) {
            this.storeService.showUpgradeModal();
        }
    }

    private async handleActivationCode(url: URL, isSubscribed): Promise<boolean> {
        const activationCode = url.searchParams.get('activationCode');
        //Standard (non campaign) upgrade link, show upgrade modal with subscribed message
        if (!activationCode && isSubscribed) {
            return true;
        }
        if (activationCode) {
            this.logger.info('Campaign Activation code found in URL', activationCode);
            this.logAnalyticsEvent.execute(AnalyticsEvent.CampaignLinkOpened, {
                campaign_code: activationCode,
            });
            const campaignDetails: CampaignModel = await this.getCampaignByCode.execute(activationCode);
            if (campaignDetails) {
                const existingCode = await this.getCampaignActivationInteractor.execute(activationCode);
                //Already subscribed with activation, show upgrade modal with subscribed message
                if (isSubscribed && existingCode) {
                    this.logger.info('User already subscribed with campaign activation');
                    return true;
                }
                //Already subscribed without activation, show error alert
                if (isSubscribed && !existingCode) {
                    this.logger.info('User already subscribed without campaign activation');
                    this.modals.alert(
                        'Campaign Activation!',
                        `This campaign is only available for new subscribers!`,
                        'warning',
                        'exclamation',
                        '(promo_activation_002)',
                    );
                    return false;
                }
                localStorage.setItem('campaignActivationDetails', JSON.stringify(campaignDetails));
                if (existingCode) {
                    this.logger.info('Campaign activated already for user, skipping save');
                } else {
                    await this.putCampaignActivationInteractor.execute(activationCode);
                }
                return true;
            } else {
                this.logger.info('Campaign not found for code', activationCode);
                this.modals.alert(
                    'Oops!',
                    `Looks like the code is invalid. Please try again or contact us at support@togethervideoapp.com`,
                    'error',
                    'error',
                    '(promo_activation_001)',
                );
                return false;
            }
        }
        return true;
    }
}
