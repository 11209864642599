import { createDeferred, Deferred } from './../../../../utilities';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    ActivityCategory,
    BookType,
    FeaturedSection,
    GetFeaturedBooksInteractor,
    GetFeaturedActivitiesInteractor,
    GetMyBooksInteractor,
    FeaturedLayout,
    BookModel,
    ActivityModel,
    BooksFilter,
    UserModel,
    PurchaseModel,
} from '@together/common';
import { SelectionType, SpringboardSection } from '../../springboard.component';
import { CallState } from '@app/shared/together/machines/call.machine';
import { SpringBoardActionTypes, SpringboardService } from '@app/shared/services/springboard.service';
import { Subscription } from 'rxjs';
import { IStoreService } from '@app/shared/services/store/store-service.interface';
import { BookPurchaseTransactionType } from '@app/shared/services/store/base-store.service';
import { UserService } from '@together/common';
import { AnimationOptions } from 'ngx-lottie';

type ActivityType = BookModel | ActivityModel;

@Component({
    selector: 'app-section-featured',
    templateUrl: './section-featured.component.html',
    styleUrls: ['./section-featured.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionFeaturedComponent implements OnChanges {
    @Input() public section: SpringboardSection;
    @Input() public users: UserModel[];
    @Input() public shouldShowAddBook: boolean;
    @Input() public callState: CallState;

    @Output() public filterByCategory = new EventEmitter<string[]>();
    @Output() public selected = new EventEmitter<ActivityType>();
    @Output() public toggleFavorite = new EventEmitter<BookModel | ActivityModel>();

    public FeaturedLayout = FeaturedLayout;
    public SpringboardSection = SpringboardSection;
    public isLoading = true;
    public sections: FeaturedSection<ActivityType>[];
    public SelectionType = SelectionType;
    public uploadedBooks: Deferred<BookModel[]>;
    public animation: AnimationOptions = {
        path: './assets/animations/loading.json',
    };
    public isLoggedIn = false;
    private subs = new Subscription();
    private currentUser: UserModel;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly getFeaturedActivities: GetFeaturedActivitiesInteractor,
        private readonly getFeaturedBooks: GetFeaturedBooksInteractor,
        private readonly getMyBooks: GetMyBooksInteractor,
        private readonly springboard: SpringboardService,
        private readonly userService: UserService,
        @Inject('StoreService') private readonly storeService: IStoreService,
    ) {}

    public async ngOnInit() {
        this.currentUser = await this.userService.getUser();
        this.isLoggedIn = await this.userService.isLoggedIn();
        this.subs.add(
            this.springboard.actions$().subscribe(action => {
                switch (action.type) {
                    case SpringBoardActionTypes.Reload:
                        this.load(true);
                        break;
                    case SpringBoardActionTypes.BookPurchased:
                        this.onBookPurchased(action.value, action.purchaseType, action.showSuccessModal);
                        break;
                    default:
                        break;
                }
            }),
        );
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        const sectionChanged = changes.section?.previousValue !== changes.section?.currentValue;

        if (!sectionChanged) {
            console.log('not changed', changes);
            return;
        }
        if (changes.hasOwnProperty('users')) {
            const hasUsersInput = typeof this.users !== 'undefined' && this.users !== null;
            if (hasUsersInput) {
                this.uploadedBooks = createDeferred();
            }
        }
        this.load();
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public select(type: SelectionType, entity: unknown): void {}

    public async refreshUploaded(refreshList = false): Promise<void> {
        this.uploadedBooks = createDeferred();
        const remoteUserId = this.users?.length > 1 ? this.users.find(u => u.id !== this.currentUser.id).id : null;
        this.getMyBooks.execute(refreshList, remoteUserId).then(
            res => {
                this.uploadedBooks.resolve(res);
                this.cdr.markForCheck();
            },
            err => {
                this.uploadedBooks.reject(err);
            },
        );
    }

    public getOwnerAvatar(book: BookModel) {
        //Books scanned, uploaded, rented and purchased by the other user will show their avatar
        const bookOwner = book.isPurchased || book.isRented ? book.purchaseDetails.userId : book.ownerId;
        if (this.callState?.matches('connected.local.springboard.home')) {
            const bookOwner = book.isPurchased || book.isRented ? book.purchaseDetails.userId : book.ownerId;
            if (bookOwner !== this.currentUser.id) {
                return this.users.find(user => user.id === bookOwner)?.getAvatarUrl();
            }
        }
        return '';
    }

    private async load(refreshList = false): Promise<void> {
        this.isLoading = true;
        this.cdr.markForCheck();

        switch (this.section) {
            case SpringboardSection.Books:
                const booksFilter: BooksFilter = {
                    ownerIds: [BookType.Included, BookType.Store],
                    featured: true,
                };
                this.sections = await this.getFeaturedBooks.execute(booksFilter, refreshList);
                await this.refreshUploaded(refreshList);
                break;
            case SpringboardSection.Games:
                this.sections = await this.getFeaturedActivities.execute(ActivityCategory.Game);
                break;
            case SpringboardSection.Activities:
                this.sections = await this.getFeaturedActivities.execute(ActivityCategory.Activity);
                break;
            case SpringboardSection.Topics:
                this.sections = await this.getFeaturedActivities.execute(ActivityCategory.Topic);
                break;
        }

        this.isLoading = false;
        this.cdr.markForCheck();
    }

    private async onBookPurchased(
        purchaseDetail: PurchaseModel,
        purchaseType: BookPurchaseTransactionType,
        showSuccessModal,
    ) {
        // this is for stripe redirect implementation
        await this.load(true);
        if (showSuccessModal) {
            let book: BookModel;
            book = (await this.uploadedBooks.promise).find(b => b.id === purchaseDetail.bookId);
            if (purchaseType === BookPurchaseTransactionType.Sale) {
                this.storeService.showBuyBookSuccessModal(book);
            } else if (purchaseType === BookPurchaseTransactionType.Rent) {
                this.storeService.showRentBookSuccessModal(book);
            }
            this.selected.emit(book);
        }
    }
}
