<div class="modal-clean modal-clean--wide modal-manage-subscription --centered">
    <div class="modal-clean__content">
        <div class="modal-clean__header">
            <div class="modal__close" (click)="close()"></div>
            <div class="modal__title">Manage Subscription</div>
        </div>
        <div class="modal-manage-subscription__container">
            <div class="plan-info-container">
                <div *ngIf="user?.subscriptionProduct">
                    <div class="plan-info-container__title">STATUS</div>
                    <div class="plan-info-container__content">
                        <div class="top-title">Your Plan</div>
                        <div class="dot-divider"></div>
                        <div class="top-plan">
                            {{ getCurrencySymbol(user?.subscriptionProduct?.currency)
                            }}{{ user?.subscriptionProduct?.price }}/{{ getSubscriptionPeriodText() }}
                        </div>
                        <div
                            *ngIf="user?.subscriptionProduct?.subscriptionState === 'active'"
                            class="top-status --active">
                            <fa-icon [icon]="['tg', 'checked-round']"></fa-icon> Active
                        </div>
                        <div
                            *ngIf="user?.subscriptionProduct?.subscriptionState === 'cancelled'"
                            class="top-status --cancelled">
                            Cancelled
                        </div>
                    </div>
                </div>
                <div class="plan-info-container__desc">
                    Unlimited hours of video calls with access to games, activities and books.
                </div>
                <div class="plan-info-container__duration">
                    <ng-container *ngIf="user?.subscriptionProduct?.subscriptionState === 'active'; else cancelled">
                        Subscription will renew on {{ user.getComputedSubscriptionEndDate() | date: 'longDate' }}
                    </ng-container>
                    <ng-template #cancelled>
                        Subscription will expire on {{ user.getComputedSubscriptionEndDate() | date: 'longDate' }}
                    </ng-template>
                </div>
                <!-- <div class="plan-info-container__duration">Member since: October 24, 2022</div> -->
                <div class="action-container" *ngIf="user?.subscriptionProduct?.subscriptionState === 'active'">
                    <div class="action-link" (click)="goToStore()">Update Payment Information</div>
                </div>
                <div class="action-container" *ngIf="user?.subscriptionProduct?.subscriptionState === 'active'">
                    <div class="action-link" (click)="goToCancel()">Cancel Membership</div>
                </div>
                <div class="action-container" *ngIf="user?.subscriptionProduct?.subscriptionState === 'cancelled'">
                    <div class="action-link" (click)="goToRenew()">Renew Membership</div>
                </div>
            </div>
            <div class="footer-link">
                <div class="action-link"><a href="mailto:support@togethervideoapp.com">Need Help?</a></div>
            </div>
        </div>
    </div>
</div>
