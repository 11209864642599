import firebase from 'firebase/app';
import { NgModule, APP_INITIALIZER, ErrorHandler, Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AbstractLogger, DeviceConsoleLogger } from '@mobilejazz/harmony-core';
import { BugfenderLogger } from '@mobilejazz/harmony-bugfender';
import { Bugfender } from '@bugfender/sdk';
import { CommonModule, UserService } from '@together/common';
import { isPlatform } from '@ionic/core';
import player from 'lottie-web';
import * as Hammer from 'hammerjs';

import { environment } from '@env/environment';
import { versions } from '@env/versions';
import { PaymentProviderType } from '@env/types';

import { AppComponent } from './app.component';
import { AppErrorHandler } from './app.error-handler';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import togetherIcons from '../assets/icons/icons';

// Note we need a separate function as it's required by the AOT compiler
// See: https://www.npmjs.com/package/ngx-lottie
export function playerFactory() {
    return player;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
        pinch: { enable: false },
        rotate: { enable: false },
    };
}

// Dynamically decide what payment provider to use
function getPaymentProvider(): PaymentProviderType {
    const hasWindow = typeof window !== 'undefined';
    const isNative = hasWindow
        ? isPlatform(window, 'capacitor') && (isPlatform(window, 'android') || isPlatform(window, 'ios'))
        : false;

    return isNative ? PaymentProviderType.NativeStore : PaymentProviderType.Stripe;
}

// Add Stripe script
if (getPaymentProvider() === PaymentProviderType.Stripe) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.stripe.com/v3/';
    document.head.appendChild(script);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HammerModule,
        CommonModule.forRoot({
            firebase: { storageBucket: environment.firebaseConfig.storageBucket },
            googleOAuthClientId: environment.googleOAuthClientId,
            loggedInLandingURL: '/home',
            loginURL: '',
            revision: versions.revision,
            version: versions.version,
        }),
        FontAwesomeModule,
        HttpClientModule,
        IonicModule.forRoot(),
        SharedModule,
        LottieModule.forRoot({
            player: playerFactory,
        }),
        LottieCacheModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        { provide: 'PaymentProviderType', useValue: getPaymentProvider() },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: ErrorHandler,
            useFactory: () => new AppErrorHandler(Bugfender, environment.production),
        },
        {
            provide: AbstractLogger,
            useFactory: () => {
                if (environment.production) {
                    // Init Bugfender
                    Bugfender.init({
                        appKey: environment.bugfenderAppKey,
                        version: versions.version,
                        build: versions.revision,
                    })
                        .then(() => {
                            console.log(`Bugfender initialized`);
                        })
                        .catch(err => {
                            console.log(`Bugfender could not be initialized, error: ${err.message}`);
                        });

                    return new BugfenderLogger(Bugfender);
                } else {
                    return new DeviceConsoleLogger();
                }
            },
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        {
            // Wait until the RemoteConfig is loaded before continuing with app initialization
            provide: APP_INITIALIZER,
            multi: true,
            deps: [AbstractLogger, 'FirebaseRemoteConfig', UserService],
            useFactory: (
                logger: AbstractLogger,
                remoteConfig: firebase.remoteConfig.RemoteConfig,
                userService: UserService,
            ) => {
                logger.info('AppModule', 'APP_INITIALIZER');

                // Must return a function that returns a promise
                // See: https://stackoverflow.com/a/56165556/379923
                return async () => {
                    if (!environment.production) {
                        remoteConfig.setLogLevel('debug');
                    }

                    await Promise.all([remoteConfig.fetchAndActivate(), userService.init()]);
                    //Update environment variables from remote config
                    environment.privacyPolicyURL = remoteConfig.getString('privacyPolicyURL');
                    environment.termsOfServiceURL = remoteConfig.getString('termsOfUseURL');
                    logger.info('AppModule', 'APP_INITIALIZER done');
                };
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIcons.apply(library, togetherIcons);
    }
}
