import { SocketActicityStatisticsHelper } from './socket-activity-statistics-helper';
import {
    ActivityCategory,
    ActivityEvent,
    ActivityModel,
    ActivityStatisticsModel,
    AnalyticsEvent,
    BookModel,
    CallMetadata,
    IActivity,
    LatencyRecording,
    LogAnalyticsEventInteractor,
    PutActivityStatisticsInteractor,
} from '@together/common';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import { ReadTogetherActivity } from './read-together.class';

export class ActivityStatisticsMonitor {
    protected activity: ActivityModel;
    protected book: BookModel;
    protected readActivity: ReadTogetherActivity;
    protected startDate: Date;
    protected endDate: Date;
    protected currentStatisticsHelper: SocketActicityStatisticsHelper;
    protected timerForPlay;

    constructor(
        protected metadata: CallMetadata,
        protected putActivityStatisticsInteractor: PutActivityStatisticsInteractor,
        protected logger: AbstractLogger,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
    ) {}

    public init(activity: ActivityModel, readActivity: ReadTogetherActivity, book: BookModel) {
        this.readActivity = null;
        this.activity = null;
        this.book = null;
        this.readActivity = readActivity;
        this.activity = activity;
        this.book = book;
    }

    public startTracking() {
        this.logActivityStartedEventToAnalytics();
        this.logOpenEventToAnalytics();
        if (!this.readActivity && this.activity) {
            this.startTrackingActivityStatistics();
        }
    }

    public closeTracking() {
        if (!this.readActivity && this.activity) {
            if (this.currentStatisticsHelper?.latencyRecording) {
                this.saveActivityStatistics(this.currentStatisticsHelper.latencyRecording);
            } else {
                this.saveActivityStatistics();
            }
        }
        if (this.timerForPlay) {
            clearTimeout(this.timerForPlay);
        }
    }

    public recordLatency(event: ActivityEvent) {
        if (!this.currentStatisticsHelper) {
            this.currentStatisticsHelper = new SocketActicityStatisticsHelper(event);
        }
        try {
            const latencyValue = event.value ? parseFloat(event.value) : 0;
            this.currentStatisticsHelper.recordLatency(latencyValue, event);
        } catch {
            this.logger.error('This should not be the case during which the activity is running already.');
            this.currentStatisticsHelper = null;
        }
    }

    private startTrackingActivityStatistics() {
        this.startDate = new Date();
    }

    private async saveActivityStatistics(latencyRecording?: LatencyRecording) {
        this.logger.info('ActivityStatisticsMonitor', `Recording activity statistics: ${this.activity.id}`);
        this.endDate = new Date();
        const timeSpent = (this.endDate.getTime() - this.startDate.getTime()) / 1000;
        let activityStatistics: ActivityStatisticsModel = {
            activityId: this.activity.id,
            callId: this.metadata.callId,
            callParticipants: [this.metadata.localParticipant.id, this.metadata.remoteParticipant.id],
            creationDate: new Date(),
            timeSpent: timeSpent,
        };
        if (latencyRecording) {
            activityStatistics.socketLatency = latencyRecording;
        }
        await this.putActivityStatisticsInteractor.execute(activityStatistics);
        this.currentStatisticsHelper = null;
    }

    private logOpenEventToAnalytics() {
        const timerAmount = 60000;
        if (this.readActivity) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.BookOpenedInCall, {
                bookName: this.book?.title,
                subscription_status: this.metadata.localParticipant?.analyticsType,
            });
            this.timerForPlay = setTimeout(() => {
                this.logAnalyticsEvent.execute(AnalyticsEvent.BookReadInCall, {
                    bookName: this.book?.title,
                    subscription_status: this.metadata.localParticipant?.analyticsType,
                });
            }, timerAmount);
        } else {
            if (this.activity?.category === ActivityCategory.Topic) {
                this.logAnalyticsEvent.execute(AnalyticsEvent.TopicStartedInCall, {
                    topicName: this.activity?.title,
                    subscription_status: this.metadata.localParticipant?.analyticsType,
                });
                this.timerForPlay = setTimeout(() => {
                    this.logAnalyticsEvent.execute(AnalyticsEvent.TopicPlayedInCall, {
                        topicName: this.activity?.title,
                        subscription_status: this.metadata.localParticipant?.analyticsType,
                    });
                }, timerAmount);
            }
        }
    }

    private logActivityStartedEventToAnalytics() {
        if (this.readActivity) {
            this.logAnalyticsEvent.execute(AnalyticsEvent.ActivityStarted, {
                activityId: this.readActivity.id,
                activityName: 'Read books together',
                callId: this.metadata.callId,
                activityType: 'book',
                subscription_status: this.metadata.localParticipant?.analyticsType,
            });
        } else {
            this.logAnalyticsEvent.execute(AnalyticsEvent.ActivityStarted, {
                activityId: this.activity?.id,
                activityName: this.activity?.title,
                callId: this.metadata.callId,
                activityType: this.activity?.category,
                subscription_status: this.metadata.localParticipant?.analyticsType,
            });
        }
    }
}
