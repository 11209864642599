import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { SimpleModalService } from '@looorent/ngx-simple-modal';

import { AcceptInviteModalComponent } from '@app/web/modals/accept-invite-modal/accept-invite-modal.component';
import { AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';
import { WaitModalService } from './wait-modal.service';
import {
    AnalyticsEvent,
    GetInviteFromURLInteractor,
    IsInviteURLInteractor,
    LogAnalyticsEventInteractor,
    UserService,
} from '@together/common';
import { ForbiddenError, NotFoundError } from '@mobilejazz/harmony-core';

@Injectable({
    providedIn: 'root', // Force it to be a singleton
})
export class InviteDeepLinkService {
    constructor(
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected getInviteFromURL: GetInviteFromURLInteractor,
        protected isInviteURL: IsInviteURLInteractor,
        protected modalService: SimpleModalService,
        protected zone: NgZone,
        protected waitModal: WaitModalService,
        protected userService: UserService,
        private router: Router,
    ) {}

    async handleURL(url: URL): Promise<void> {
        if (!this.isInviteURL.execute(url)) {
            return;
        }

        if (!(await this.userService.isLoggedIn())) {
            const inviteCode = url.searchParams.get('code');
            this.zone.run(() => {
                this.router.navigate(['/invites'], {
                    queryParams: {
                        code: inviteCode,
                    },
                });
            });
            return;
        }

        try {
            this.waitModal.show();
            const invite = await this.getInviteFromURL.execute(url);
            const user = await this.userService.getUser();
            if (user.id === invite.creator.id) {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: `You can't add yourself as a contact. Share this code with someone you want to invite.`,
                    type: 'warning',
                    icon: 'exclamation',
                });
                this.waitModal.hide();
                return;
            }
            this.logAnalyticsEvent.execute(AnalyticsEvent.InviteLinkOpenedByRegisteredUser, {
                subscription_status: user.analyticsType,
            });
            this.modalService.addModal(AcceptInviteModalComponent, { invite });
        } catch (err) {
            if (err instanceof NotFoundError) {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: `We couldn't find an invite for that code and user.`,
                    type: 'warning',
                    icon: 'exclamation',
                });
            } else if (err instanceof ForbiddenError) {
                this.logAnalyticsEvent.execute(AnalyticsEvent.InviteLinkOpenedButUserNotRegistered);
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: `Please login or register to accept the invitation.`,
                    type: 'warning',
                    icon: 'exclamation',
                });
            } else {
                this.modalService.addModal(AlertModalComponent, {
                    title: 'Oops!',
                    message: `We couldn't process your invite link. Please try again.`,
                    type: 'error',
                    icon: 'error',
                });

                throw err;
            }
        } finally {
            this.waitModal.hide();
        }
    }
}
