import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivityModel } from '@together/common';

@Component({
    selector: 'app-activity-thumbnail',
    templateUrl: './activity-thumbnail.component.html',
    styleUrls: ['./activity-thumbnail.component.scss'],
})
export class ActivityThumbnailComponent implements OnInit {
    @Input() activity: ActivityModel;
    @Input() isFav = false;
    @Input() onlyImage = false;

    @Output() public selected = new EventEmitter<ActivityModel>();

    constructor() {}
    ngOnInit() {}

    getAgeRange() {
        if (this.activity.ageMax === -1) {
            return `${this.activity.ageMin}+`;
        } else {
            return `${this.activity.ageMin}–${this.activity.ageMax}`;
        }
    }
}
