<div class="book-thumb book-thumb--book --featured" (click)="selected.emit(activity)">
    <div class="book-thumb__image-wrapper">
        <div
            class="book-thumb__image"
            [ngStyle]="{
                'background-image': activity.featuredImageURL
                    ? 'url(' + activity.featuredImageURL + ')'
                    : 'url(' + activity.coverImageURL + ')'
            }"></div>
        <button class="book-thumb__user-fav button-v2 --icon-only --red" *ngIf="isFav">
            <fa-icon [icon]="['tg', 'heart-solid']"></fa-icon>
        </button>
    </div>
</div>
