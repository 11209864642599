import { LegalCheckService } from './shared/services/legal-check.service';
import { PushService } from './shared/services/push.service';
import { PromoPaymentDeepLinkService } from './shared/services/promo-payment-deep-link.service';
import { environment } from '@env/environment';
import { MigrateResult, Preferences } from '@capacitor/preferences';
import { AbstractLogger } from '@mobilejazz/harmony-core';
import { Component, Inject, NgZone } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { filter, first } from 'rxjs/operators';

import { CallRouterMachineFactory } from './shared/together/machines/call-router.machine.factory';
import { CallRouterService } from './shared/services/call-router.service';
import { ContactsService } from './shared/services/contacts.service';
import { InviteDeepLinkService } from './shared/services/invite-deep-link.service';
import { IStoreService } from './shared/services/store/store-service.interface';
import { NativeStoreRedirectService } from './shared/services/store/native-store-redirect.service';
import { PromoCodeDeepLinkService } from './shared/services/promo-code-deep-link.service';
import {
    MixpanelService,
    UserService,
    GetSyncPurchasesInteractor,
    SetSyncPurchasesInteractor,
    CheckBrowserCompatibilityInteractor,
    BrowserCompatibility,
    SingularService,
    UserModel,
} from '@together/common';
import { Subscription, timer } from 'rxjs';
import { WaitModalService } from './shared/services/wait-modal.service';
import { waitPromise } from './shared/utilities';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import { AlertModalComponent } from './web/modals/alert-modal/alert-modal.component';
import { Device, DeviceInfo } from '@capacitor/device';
import { UpgradeDeepLinkService } from './shared/services/upgrade-deep-link.service';
import { versions } from '@env/versions';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    private minSessionLength: number = 10000; //10 seconds
    private appSessionStartTime: number;
    private deviceInfo: DeviceInfo;

    constructor(
        protected callRouterMachineFactory: CallRouterMachineFactory,
        protected callRouterService: CallRouterService,
        protected contactsService: ContactsService,
        protected inviteDeepLinkService: InviteDeepLinkService,
        protected promoCodeDeepLinkService: PromoCodeDeepLinkService,
        protected upgradeDeepLinkService: UpgradeDeepLinkService,
        protected promoPaymentDeepLinkService: PromoPaymentDeepLinkService,
        protected logger: AbstractLogger,
        protected platform: Platform,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        @Inject('StoreService') protected storeService: IStoreService,
        protected nativeStoreRedirectService: NativeStoreRedirectService,
        protected translateService: TranslateService,
        protected userService: UserService,
        protected mixpanelService: MixpanelService,
        protected waitService: WaitModalService,
        protected getSyncPurchasesInteractor: GetSyncPurchasesInteractor,
        protected setSyncPurchasesInteractor: SetSyncPurchasesInteractor,
        protected checkBrowserCompatibilityInteractor: CheckBrowserCompatibilityInteractor,
        protected modalService: SimpleModalService,
        protected singularService: SingularService,
        protected pushService: PushService,
        protected legalCheckService: LegalCheckService,
        protected zone: NgZone,
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.appSessionStartTime = Date.now();
        this.logger.info('AppComponent', 'Initialize App');

        this.platform.ready().then(async () => {
            this.deviceInfo = await Device.getInfo();
            this.checkForBrowserCompatibility();
            // this.statusBar.styleDefault();
            Preferences.migrate().then((res: MigrateResult) => {
                Preferences.removeOld();
            });
        });

        // i18n
        const defaultLang = 'en';
        const supportedLangs = ['en'];
        const userLang = this.translateService.getBrowserLang();
        const lang = supportedLangs.includes(userLang) ? userLang : defaultLang;

        this.translateService.setDefaultLang(defaultLang);
        this.translateService.use(lang);

        // Remove splash screen upon first navigation
        const filterByNavigationEnd = filter((e): e is NavigationEnd => e instanceof NavigationEnd);

        this.router.events.pipe(filterByNavigationEnd, first()).subscribe(async val => {
            this.logger.info('AppComponent', 'Hide splash screen');
            document.querySelector('[data-loading-splash]').remove();
            await SplashScreen.hide();
        });

        // Start/Stop related services
        let lastHasUser = null;

        //Singular
        this.singularService.initSingular(
            environment.singularConfig,
            this.platform.is('cordova'),
            environment.production,
        );
        //Handler for singular deep links
        this.singularService.deepLink$().subscribe(deeplinkData => {
            let url = new URL(deeplinkData.deeplink);
            if (deeplinkData.passthrough) {
                url.search = deeplinkData.passthrough;
            }
            this.appendDeepLinkParamsToUrl(url.pathname + url.search);
            this.upgradeDeepLinkService.handleURL(url);
        });

        this.userService.getUser$().subscribe(async user => {
            // TODO: Hide this logic inside `UserService.getAuthChange$` (or similar)
            // Check that the user logged-in state has changed
            const hasUser = !!user;
            const stateChanged = lastHasUser !== hasUser;
            lastHasUser = hasUser;

            // State hasn't changed, short-circuit as we don't
            // want to run the below code twice on the same state
            if (!stateChanged) {
                return;
            }

            // Only if state changed start/stop services
            if (hasUser) {
                this.logger.info('AppComponent', 'Login. Start services.');
                if (!user.recentlyCreated) {
                    this.legalCheckService.start();
                }
                this.deviceInfo = await Device.getInfo();
                await this.userService.setUserDeviceInfo(user, versions.version, versions.revision);
                this.userService.updateDeviceInfo(user);
                this.contactsService.start(user);
                this.callRouterService.start(this.callRouterMachineFactory.create(user));
                this.mixpanelService.signIn(user);
                this.singularService.login(user.id);
                this.pushService.initialisePushNotification();
                this.storeService.init();
                await this.storeService.loginWithProvider(user);
                const isSyncCompleted = await this.getSyncPurchasesInteractor.execute();
                //Puchases should be synced once
                if (!isSyncCompleted) {
                    await this.syncPurchases();
                }
                if (!this.platform.is('android')) {
                    this.trackAppSessionEventForWeb();
                }
            } else {
                this.logger.info('AppComponent', 'Logout. Stop services.');
                this.contactsService.stop();
                this.callRouterService.stop();
            }
        });

        // Handle deep links
        App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
            const url = new URL(data.url);
            this.appendDeepLinkParamsToUrl(url.pathname + url.search);
            this.inviteDeepLinkService.handleURL(url);
            this.promoCodeDeepLinkService.handleURL(url);
            this.upgradeDeepLinkService.handleURL(url);
        });

        // For android, a session is equivalent to the time the user has the app is foreground.
        // Track as a new app session on every app background/foreground states
        App.addListener('appStateChange', async ({ isActive }) => {
            if (!isActive) {
                this.trackAppSessionEventForAndroid();
            } else if (isActive) {
                this.appSessionStartTime = Date.now();
                //If the app comes back to foregorund after manage subscription action, user should be refreshed
                if (this.storeService.shouldRefreshUserAfterManageSubscription) {
                    await this.userService.reload();
                }
            }
        });

        //Handle redirect from stripe if user is not logged in. Logged in users are handled via the stripe result handler on layout
        this.promoPaymentDeepLinkService.handleURL(new URL(window.location.href));

        // Redirect to the store if accessing web-page in iOS or Android
        this.nativeStoreRedirectService.check();
    }

    // For web, we cannot track app closure and hence session length cannot be set.
    // Only sending ae_session event after 10 secs
    private async trackAppSessionEventForWeb() {
        const timerSource = timer(this.minSessionLength);

        timerSource.subscribe(val => {
            this.mixpanelService.track('$ae_session', {
                $os_version: this.deviceInfo.osVersion,
            });
        });
    }

    //For android, we can compute session length and also increment the user properties related to session (similar to ios)
    private async trackAppSessionEventForAndroid() {
        const sessionLength = Date.now() - this.appSessionStartTime;
        if (sessionLength >= this.minSessionLength) {
            this.mixpanelService.track('$ae_session', {
                $ae_session_length: sessionLength / 1000,
                $os_version: this.deviceInfo.osVersion,
            });
            this.mixpanelService.increment('$ae_total_app_sessions', 1);
            this.mixpanelService.increment('$ae_total_app_session_length', sessionLength / 1000);
        }
    }

    private async syncPurchases(): Promise<void> {
        try {
            this.waitService.show();
            await waitPromise(this.storeService.syncPurchases(), 4000);
            this.setSyncPurchasesInteractor.execute();
        } finally {
            this.waitService.hide();
        }
    }

    private checkForBrowserCompatibility() {
        const browserCompatibility: BrowserCompatibility = this.checkBrowserCompatibilityInteractor.execute();
        if (!browserCompatibility.isCompatible) {
            this.modalService.addModal(AlertModalComponent, {
                title: 'Update your browser',
                message: `It looks like you’re using an old web browser. Update to a current version for the best Together experience.`,
                type: 'warning',
            });
        }
    }

    private appendDeepLinkParamsToUrl(pathUrl: string) {
        //Append the deep link url to current url params to handle the deep link in the same way as if the user had clicked on the link
        this.zone.run(() => {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { redirectTo: pathUrl },
                queryParamsHandling: 'merge',
                skipLocationChange: true,
            });
        });
    }
}
