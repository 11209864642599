<app-section-title-portal>
    <h3 *ngIf="section === SpringboardSection.Books">Read Together</h3>
    <h3 *ngIf="section === SpringboardSection.Games">Play Together</h3>
    <h3 *ngIf="section === SpringboardSection.Activities">Draw Together</h3>
    <h3 *ngIf="section === SpringboardSection.Topics">Play Together</h3>
    <div class="action-container">
        <button class="button-v2 --icon-only" (click)="filterByCategory.emit()">
            <fa-icon [icon]="['tg', 'search']"></fa-icon>
        </button>
        <app-add-book-btn [style]="'button'" *ngIf="shouldShowAddBook" (onBookAdded)="refreshUploaded(true)">
        </app-add-book-btn>
    </div>
</app-section-title-portal>

<div class="section-container" *ngIf="!isLoading; else loading">
    <!-- Category Sections-->
    <div *ngFor="let sectionItem of sections" class="section">
        <div class="section__title-wrapper">
            <div class="section__title">{{ sectionItem.label }}</div>
            <div class="section__title-link">
                <span (click)="filterByCategory.emit([sectionItem.category])">See more</span>
            </div>
        </div>
        <div class="section__content" *ngIf="sectionItem.layout === FeaturedLayout.Normal">
            <div class="horizontal-scroll horizontal-scroll" *ngIf="section === SpringboardSection.Books">
                <app-book-thumbnail
                    *ngFor="let item of sectionItem.items"
                    [book]="item"
                    [allowPurchase]="!callState"
                    [isFav]="users[0]?.isFavoriteBook(item.id)"
                    (selected)="selected.emit($event)"
                    (toggleFavorite)="toggleFavorite.emit($event)"></app-book-thumbnail>
            </div>
            <div class="horizontal-scroll horizontal-scroll" *ngIf="section !== SpringboardSection.Books">
                <app-activity-thumbnail
                    *ngFor="let item of sectionItem.items"
                    [activity]="item"
                    [isFav]="users[0]?.isFavoriteActivity(item.id)"
                    (selected)="selected.emit($event)">
                </app-activity-thumbnail>
            </div>
        </div>
        <div class="section__content" *ngIf="sectionItem.layout === FeaturedLayout.Featured">
            <div class="horizontal-scroll horizontal-scroll--featured" *ngIf="section === SpringboardSection.Books">
                <app-featured-book-thumbnail
                    *ngFor="let item of sectionItem.items"
                    [book]="item"
                    [allowPurchase]="!callState"
                    [isFav]="users[0]?.isFavoriteBook(item.id)"
                    (selected)="selected.emit($event)"
                    (toggleFavorite)="toggleFavorite.emit($event)">
                </app-featured-book-thumbnail>
            </div>
            <div class="horizontal-scroll horizontal-scroll--featured" *ngIf="section !== SpringboardSection.Books">
                <app-featured-activity-thumbnail
                    *ngFor="let item of sectionItem.items"
                    [activity]="item"
                    [isFav]="users[0]?.isFavoriteActivity(item.id)"
                    (selected)="selected.emit($event)"
                    (toggleFavorite)="toggleFavorite.emit($event)">
                </app-featured-activity-thumbnail>
            </div>
        </div>
    </div>
    <!-- My Books Section-->
    <div class="section --my-books" *ngIf="isLoggedIn && section === SpringboardSection.Books">
        <div class="section__title-wrapper">
            <div class="section__title">My Books</div>
            <div class="section__title-link">
                <span (click)="filterByCategory.emit(['my_books'])">See more</span>
            </div>
        </div>
        <div class="section__content" *ngIf="uploadedBooks.isResolved">
            <div class="horizontal-scroll horizontal-scroll" *ngIf="(uploadedBooks.promise | async)?.length > 0">
                <app-add-book-btn
                    [style]="'thumb'"
                    [btnClass]="' --white --bg-purple'"
                    *ngIf="shouldShowAddBook"
                    (onBookAdded)="refreshUploaded(true)">
                </app-add-book-btn>
                <app-book-thumbnail
                    *ngFor="let item of uploadedBooks.promise | async"
                    [book]="item"
                    [isFav]="users[0]?.isFavoriteBook(item.id)"
                    [allowPurchase]="!callState"
                    [ownerAvatar]="getOwnerAvatar(item)"
                    (selected)="selected.emit($event)"
                    (toggleFavorite)="toggleFavorite.emit($event)">
                </app-book-thumbnail>
            </div>
            <div class="section__content__empty-container" *ngIf="(uploadedBooks.promise | async)?.length == 0">
                <div class="section__content__empty-container__box">
                    <app-add-book-btn
                        [style]="'button'"
                        *ngIf="shouldShowAddBook"
                        [btnClass]="' --white --bg-purple'"
                        (onBookAdded)="refreshUploaded(true)">
                    </app-add-book-btn>
                    <div class="section__content__empty-container__box__text">
                        You can upload and add your own books!
                    </div>
                    <div class="section__content__empty-container__box__image">
                        <img src="/assets/add-book.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="loading-animation">
        <ng-lottie [options]="animation"></ng-lottie>
    </div>
</ng-template>
