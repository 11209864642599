import { DropdownSelectModalComponent } from './../../../../../web/modals/dropdown-select-modal/dropdown-select-modal.component';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import isEqual from 'lodash.isequal';
import firebase from 'firebase/app';
import { SpringboardSection } from '../../springboard.component';
import {
    AgeOptions,
    LanguageOptions,
    GetBookCategoriesInteractor,
    GetGameCategoriesInteractor,
    GetTopicCategoriesInteractor,
    GetActivityCategoriesInteractor,
    SearchBooksInteractor,
    SearchActivitiesInteractor,
    BooksFilter,
    GamesFilter,
    ActivityCategory,
    BookType,
    BookModel,
    ActivityModel,
    UserModel,
    SelectFieldConfig,
    SelectedFilterType,
    SelectOptionEntry,
    applyFilterInResultSet,
    DrawLearnFilter,
    TopicsFilter,
    GetMyBooksInteractor,
    UserService,
} from '@together/common';
import { SpringBoardActionTypes, SpringboardService } from '@app/shared/services/springboard.service';
import { CallState } from '@app/shared/together/machines/call.machine';
import { AnimationOptions } from 'ngx-lottie';
import { AddBookModalComponent } from '@app/web/modals/add-book-modal/add-book-modal.component';

type FieldConfig = SelectFieldConfig;
type ActivityType = BookModel | ActivityModel;

@Component({
    selector: 'app-section-search',
    templateUrl: './section-search.component.html',
    styleUrls: ['./section-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionSearchComponent implements OnChanges, OnInit, OnDestroy {
    @Input() public section: SpringboardSection;
    @Input() public filters: Record<string, SelectedFilterType>;
    @Input() public users: UserModel[];
    @Input() public callState: CallState;

    @Output() public filtersChanged = new EventEmitter<Record<string, SelectedFilterType>>();
    @Output() public selected = new EventEmitter<ActivityType>();
    @Output() public toggleFavorite = new EventEmitter<BookModel | ActivityModel>();
    @Output() public hideFilters = new EventEmitter<void>();

    @ViewChild('searchContainer') container: ElementRef<HTMLDivElement>;

    public config: FieldConfig[];
    public form: UntypedFormGroup;
    /** Loading state for dropdowns, this is `true` only until is initialised */
    public isLoading = true;
    /** Searching state for content */
    public isSearching = true;
    public items: unknown[];
    public showError = false;
    public SpringboardSection = SpringboardSection;
    public isInfiniteScrolling = false;
    public isNextPagePresent = true;
    public animation: AnimationOptions = {
        path: './assets/animations/loading.json',
    };

    private cursor = undefined;
    private limit: number;
    private filterBookFetchLimit: number;
    private subs = new Subscription();
    private currentUser: UserModel;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly fb: UntypedFormBuilder,
        private readonly getBookCategories: GetBookCategoriesInteractor,
        private readonly getGameCategories: GetGameCategoriesInteractor,
        private readonly getActivityCategories: GetActivityCategoriesInteractor,
        private readonly getTopicCategories: GetTopicCategoriesInteractor,
        private readonly searchBooks: SearchBooksInteractor,
        private readonly searchActivities: SearchActivitiesInteractor,
        private readonly springboard: SpringboardService,
        private modalService: SimpleModalService,
        @Inject('FirebaseRemoteConfig')
        private readonly remoteConfig: firebase.remoteConfig.RemoteConfig,
        private readonly getMyBooks: GetMyBooksInteractor,
        private readonly userService: UserService,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            'filters' in changes &&
            !changes.filters.firstChange &&
            !isEqual(changes.filters.previousValue, changes.filters.currentValue)
        ) {
            this.search();
        }
    }

    public async ngOnInit(): Promise<void> {
        if (await this.userService.isLoggedIn()) {
            this.currentUser = await this.userService.getUser();
        }
        this.subs.add(
            this.springboard.actions$().subscribe(action => {
                switch (action.type) {
                    case SpringBoardActionTypes.BookPurchased:
                        this.search(true);
                        break;
                    default:
                        break;
                }
            }),
        );

        switch (this.section) {
            case SpringboardSection.Books:
                this.filterBookFetchLimit = this.remoteConfig.getNumber('filterBookFetchLimit');
                this.limit = this.filterBookFetchLimit;
                const [bookCategories] = await Promise.all([this.getBookCategories.execute()]);
                let canShowLanguageFilter = this.remoteConfig.getBoolean('showsBookLanguageFilter');
                this.config = [
                    {
                        type: 'select',
                        id: 'category',
                        title: 'Categories',
                        multiple: true,
                        control: new UntypedFormControl(this.filters.category ?? null),
                        options: bookCategories.map(c => ({ label: c.labels.en, value: c.id })),
                    },
                    {
                        type: 'select',
                        id: 'age',
                        title: 'Age',
                        control: new UntypedFormControl(this.filters.age ?? null),
                        options: AgeOptions,
                    },
                ];
                if (canShowLanguageFilter) {
                    this.config.push({
                        type: 'select',
                        id: 'language',
                        title: 'Language',
                        control: new UntypedFormControl(this.filters.language ?? null),
                        options: LanguageOptions,
                    });
                }
                break;

            case SpringboardSection.Games:
                const [gameCategories] = await Promise.all([this.getGameCategories.execute()]);
                this.config = [
                    {
                        type: 'select',
                        id: 'category',
                        title: 'Categories',
                        multiple: true,
                        control: new UntypedFormControl(this.filters.category ?? null),
                        options: gameCategories.map(c => ({ label: c.labels.en, value: c.id })),
                    },
                    {
                        type: 'select',
                        id: 'age',
                        title: 'Age',
                        control: new UntypedFormControl(this.filters.age ?? null),
                        options: AgeOptions,
                    },
                ];
                break;

            case SpringboardSection.Activities:
                const [activityCategories] = await Promise.all([this.getActivityCategories.execute()]);
                this.config = [
                    {
                        type: 'select',
                        id: 'category',
                        title: 'Categories',
                        multiple: true,
                        control: new UntypedFormControl(this.filters.category ?? null),
                        options: activityCategories.map(c => ({ label: c.labels.en, value: c.id })),
                    },
                    {
                        type: 'select',
                        id: 'age',
                        title: 'Age',
                        control: new UntypedFormControl(this.filters.age ?? null),
                        options: AgeOptions,
                    },
                ];
                break;

            case SpringboardSection.Topics:
                const [topicCategories] = await Promise.all([this.getTopicCategories.execute()]);
                this.config = [
                    {
                        type: 'select',
                        id: 'category',
                        title: 'Categories',
                        multiple: true,
                        control: new UntypedFormControl(this.filters.category ?? null),
                        options: topicCategories.map(c => ({ label: c.labels.en, value: c.id })),
                    },
                    {
                        type: 'select',
                        id: 'age',
                        title: 'Age',
                        control: new UntypedFormControl(this.filters.age ?? null),
                        options: AgeOptions,
                    },
                ];
                break;
        }

        this.form = this.fb.group(this.config.reduce((cfg, { id, control }) => ({ ...cfg, [id]: control }), {}));

        this.subs.add(
            this.form.valueChanges.subscribe(values => {
                this.filtersChanged.emit(values);
            }),
        );

        this.isLoading = false;
        setTimeout(() => {
            this.container.nativeElement.scrollIntoView();
        }, 10);
        this.search();
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public retrySearch(): void {
        this.search();
    }

    public clearAllFilters(): void {
        this.form.reset();
        this.filters = {};
        this.search();
    }

    public openDropdownSelectModal(fieldConfig: FieldConfig) {
        this.modalService
            .addModal(DropdownSelectModalComponent, {
                fieldConfig,
                selectedFilters: this.filters[fieldConfig.id],
            })
            .subscribe(result => {
                if (result) {
                    this.form.controls[fieldConfig.id].patchValue(result[fieldConfig.id]);
                }
            });
    }

    public getSelectedLabelFromConfig(filterName, value) {
        if (value === 'my_books') {
            return 'My Books';
        }
        const options = this.config?.find(c => c.id === filterName)?.options;
        return options?.find(o => o.value === value)?.label;
    }

    public getSelectedLabel(options: SelectOptionEntry<number | string>[], value) {
        return options.find(o => o.value === value)?.label;
    }

    public getCountOfSelected(fieldConfig) {
        const selectedLength = (this.filters[fieldConfig.id] as [])?.length;
        return selectedLength;
    }

    public removeSelected(fieldConfig: FieldConfig, selectedValue) {
        const newValue = this.form.controls[fieldConfig.id].value.filter(option => {
            return option !== selectedValue;
        });
        this.form.controls[fieldConfig.id].setValue(newValue);
    }

    public hasFiltersSelected() {
        if (this.filters) {
            return Object.keys(this.filters).some(f => {
                const isValueArray: boolean = Array.isArray(this.filters[f]) && (this.filters[f] as []).length > 0;
                const isValueNonArray: boolean = !Array.isArray(this.filters[f]);
                return !!this.filters[f] && (isValueArray || isValueNonArray);
            });
        }
        return false;
    }

    public isOptionDisabled(fieldConfig, option): boolean {
        return this.getCountOfSelected(fieldConfig) >= 10 && !fieldConfig.control.value.find(el => el == option);
    }

    public canShowFilters() {
        if ((this.filters.category as [])?.length && this.filters.category[0] === 'my_books') {
            return false;
        }
        return true;
    }

    public onScroll() {
        if (!this.isSearching && !this.isInfiniteScrolling && this.isNextPagePresent) {
            this.isInfiniteScrolling = true;
            this.limit = this.limit + this.filterBookFetchLimit;
            this.search()
                .then(() => {
                    this.isInfiniteScrolling = false;
                })
                .catch(() => {
                    this.isInfiniteScrolling = false;
                });
        }
    }

    public async showAddBookModal(): Promise<void> {
        const newBook = await this.modalService
            .addModal(AddBookModalComponent, null, {
                closeOnEscape: false,
                closeOnClickOutside: false,
            })
            .toPromise();

        if (newBook) {
            await this.fetchMyBooks(true);
            this.cdr.markForCheck();
        }
    }

    private async search(refreshList = false): Promise<void> {
        this.showError = false;
        this.isSearching = true;
        this.cdr.markForCheck();

        try {
            switch (this.section) {
                case SpringboardSection.Books:
                    if (
                        (this.filters.category as [])?.length &&
                        this.filters.category[0] === 'my_books' &&
                        this.currentUser?.id
                    ) {
                        await this.fetchMyBooks(refreshList);
                    } else {
                        let booksFilter: BooksFilter = {
                            ownerIds: [BookType.Included, BookType.Store],
                            limit: this.limit,
                        };
                        if (this.filters.category) {
                            booksFilter.subCategories = this.filters.category as [];
                        }
                        if (this.filters.age) {
                            booksFilter.age = this.filters.age as number;
                        }
                        if (this.filters.language) {
                            booksFilter.language = this.filters.language as string;
                        }
                        const results = await this.searchBooks.execute(booksFilter, refreshList);
                        if (this.isInfiniteScrolling) {
                            this.items = [
                                ...this.items,
                                ...results.slice(this.limit - this.filterBookFetchLimit, results.length),
                            ];
                        } else {
                            this.items = results;
                        }
                        this.isNextPagePresent = this.items.length >= this.limit;
                    }

                    break;

                case SpringboardSection.Games:
                    let gamesFilter: GamesFilter = {};
                    if (this.filters.category) {
                        gamesFilter.subCategories = this.filters.category as [];
                    }
                    if (this.filters.age) {
                        gamesFilter.age = this.filters.age as number;
                    }
                    const gameResults = await this.searchActivities.execute(ActivityCategory.Game, gamesFilter);
                    this.items = gameResults.filter(applyFilterInResultSet(gamesFilter));
                    break;

                case SpringboardSection.Activities:
                    let activitiesFilter: DrawLearnFilter = {};
                    if (this.filters.category) {
                        activitiesFilter.subCategories = this.filters.category as [];
                    }
                    if (this.filters.age) {
                        activitiesFilter.age = this.filters.age as number;
                    }
                    const activityResults = await this.searchActivities.execute(
                        ActivityCategory.Activity,
                        activitiesFilter,
                    );
                    this.items = activityResults.filter(applyFilterInResultSet(activitiesFilter));
                    break;

                case SpringboardSection.Topics:
                    let topicsFilter: TopicsFilter = {};
                    if (this.filters.category) {
                        topicsFilter.subCategories = this.filters.category as [];
                    }
                    if (this.filters.age) {
                        topicsFilter.age = this.filters.age as number;
                    }
                    const topicResults = await this.searchActivities.execute(ActivityCategory.Topic, topicsFilter);
                    this.items = topicResults.filter(applyFilterInResultSet(topicsFilter));
                    break;
            }
        } catch {
            this.showError = true;
        }

        this.isSearching = false;
        this.cdr.markForCheck();
    }

    public async fetchMyBooks(refreshList = false): Promise<void> {
        const remoteUserId = this.users?.length > 1 ? this.users.find(u => u.id !== this.currentUser.id).id : null;
        const myBooksResults = await this.getMyBooks.execute(refreshList, remoteUserId);
        this.items = myBooksResults;
    }
}
