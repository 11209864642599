import firebase from 'firebase/app';
import { StateNode, assign, spawn } from 'xstate';
import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { SimpleModalService } from '@looorent/ngx-simple-modal';
import { PutInteractor, IdQuery, AbstractLogger } from '@mobilejazz/harmony-core';

import { ActionType, AlertModalComponent } from '@app/web/modals/alert-modal/alert-modal.component';
import { AppRatingModalComponent } from '@app/web/modals/app-rating-modal/app-rating-modal.component';
import { CallFeedbackModalComponent } from '@app/web/modals/call-feedback-modal/call-feedback-modal.component';
import { CallHighlightModalComponent } from '@app/web/modals/call-highlight-modal/call-highlight-modal.component';
import { CallMachineFactory } from './call.machine.factory';
import { PermissionsDeniedModalComponent } from '@app/web/modals/permissions-denied-modal/permissions-denied-modal.component';
import { PermissionsPromptModalComponent } from '@app/web/modals/permissions-prompt-modal/permissions-prompt-modal.component';
import {
    AcceptCallInteractor,
    AnalyticsEvent,
    CallMode,
    CallModel,
    CallStatus,
    CallStatusObserverInteractor,
    CanStoreCallHighlightsInteractor,
    ClearCallHighlightsInteractor,
    ClearPhotoboothImagesInteractor,
    CreateCallInteractor,
    GetCallFeedbackModalInteractor,
    GetMaxCallDurationInteractor,
    HasCallHighlightsInteractor,
    HasPhotoboothImagesInteractor,
    IncomingCallObserverInteractor,
    IncreaseUserCompletedCallsInteractor,
    IsFreePlanCallInteractor,
    LogAnalyticsEventInteractor,
    PutCallHighlightInteractor,
    ShouldShowPermissionsPromptInteractor,
    ShowCallFeedbackModal,
    UserModel,
    CallStatusWithRemoteParticipant,
} from '@together/common';

import { environment } from '@env/environment';

import {
    CallAcceptedEvent,
    CallRouterContext,
    CallRouterEvent,
    CallRouterMachine,
    CallRouterStateSchema,
    PointScoredEvent,
} from './call-router.machine';
import { IStoreService } from '@app/shared/services/store/store-service.interface';
import { CallRouterService } from '@app/shared/services/call-router.service';

@Injectable()
export class CallRouterMachineFactory {
    constructor(
        protected acceptCallInteractor: AcceptCallInteractor,
        protected callMachineFactory: CallMachineFactory,
        protected callStatusObserver: CallStatusObserverInteractor,
        protected canStoreCallHighlights: CanStoreCallHighlightsInteractor,
        protected clearCallHighlights: ClearCallHighlightsInteractor,
        protected clearPhotoboothImageInteractor: ClearPhotoboothImagesInteractor,
        protected createCall: CreateCallInteractor,
        @Inject('FirebaseFirestore') protected firestore: firebase.firestore.Firestore,
        @Inject('FirebaseFunctions') protected functions: firebase.functions.Functions,
        protected getCallFeedbackModal: GetCallFeedbackModalInteractor,
        protected getMaxCallDuration: GetMaxCallDurationInteractor,
        protected hasCallHighlights: HasCallHighlightsInteractor,
        protected hasPhotoboothImages: HasPhotoboothImagesInteractor,
        protected incomingCallObserver: IncomingCallObserverInteractor,
        protected increaseUserCompletedCalls: IncreaseUserCompletedCallsInteractor,
        protected isFreePlanCall: IsFreePlanCallInteractor,
        protected logAnalyticsEvent: LogAnalyticsEventInteractor,
        protected logger: AbstractLogger,
        protected modalService: SimpleModalService,
        @Inject('PutInteractor<Call>') protected putCall: PutInteractor<CallModel>,
        protected putCallHighlight: PutCallHighlightInteractor,
        protected shouldShowPermissionsPrompt: ShouldShowPermissionsPromptInteractor,
        @Inject('StoreService') protected storeService: IStoreService,
        protected callRouterService: CallRouterService,
    ) {}

    create(user: UserModel): StateNode<CallRouterContext, CallRouterStateSchema, CallRouterEvent> {
        const receivingCallSound = new Audio('./assets/sounds/receiving-call.mp3');
        receivingCallSound.loop = true;

        return CallRouterMachine.withConfig({
            actions: {
                acceptCall: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Accept call');
                    this.acceptCallInteractor.execute(context.metadata.callId);
                },
                assignCallTimes: assign({
                    callStartTime: () => {
                        const startTime = Date.now();

                        this.logger.info('CallRouterMachine', `callStartTime=${startTime}`);

                        return startTime;
                    },
                    callEndTime: context => {
                        const metadata = context.metadata;
                        const maxCallDuration = this.getMaxCallDuration.execute(
                            metadata.localParticipant,
                            metadata.remoteParticipant,
                        );
                        const callEndTime = Date.now() + maxCallDuration * 1000;

                        this.logger.info(
                            'CallRouterMachine',
                            `maxCallDuration=${maxCallDuration} callEndTime=${callEndTime}`,
                        );

                        return callEndTime;
                    },
                }),
                cancelCall: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Cancel call');
                    this.putCall.execute({ status: CallStatus.Cancelled }, new IdQuery(context.metadata.callId));
                },
                clearCallHighlights: (context, event) => {
                    this.clearCallHighlights.execute();
                },
                clearPhotoboothImages: (context, event) => {
                    this.clearPhotoboothImageInteractor.execute();
                },
                completeCall: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Complete call');
                    this.putCall.execute({ status: CallStatus.Completed }, new IdQuery(context.metadata.callId));
                },
                denyCall: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Deny call');
                    this.putCall.execute({ status: CallStatus.Denied }, new IdQuery(context.metadata.callId));
                },
                errorCall: async (context, event) => {
                    this.logger.info('CallRouterMachine', 'Error call');
                    try {
                        await this.putCall.execute(
                            { status: CallStatus.Errored },
                            new IdQuery(context.metadata.callId),
                        );
                        this.logAnalyticsEvent.execute(AnalyticsEvent.CallFailed);
                    } catch (err) {
                        this.logger.info('CallRouterMachine', `Can't change call status as it doesn't exist yet`);
                    }
                },
                hangUpCall: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Hang-up call');

                    // `context.call` might not be defined (e.g. if remote cancels call when state is `busy.ringing.dialog`)
                    if (context.call) {
                        // Stop child call machine
                        context.call.send({ type: 'DISCONNECT' });
                    }
                },
                increaseInteractionCount: assign({
                    interactionCount: context => context.interactionCount + 1,
                }),
                increaseUserCompletedCalls: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Increase user completed calls');
                    this.increaseUserCompletedCalls.execute(context.metadata.localParticipant, context.callStartTime);
                },
                missedCall: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Missed call');
                    this.putCall.execute({ status: CallStatus.Missed }, new IdQuery(context.metadata.callId));
                },
                ringingSoundPlay: () => {
                    this.logger.info('CallRouterMachine', 'Start ringing sound');
                    receivingCallSound.play();
                },
                ringingSoundStop: () => {
                    this.logger.info('CallRouterMachine', 'Stop ringing sound');
                    receivingCallSound.pause();
                    receivingCallSound.currentTime = 0;
                },
                ringCall: (context, event) => {
                    const payload = {
                        status: CallStatus.Ringing,
                        toDeviceAppVersion: user.currentDevice.appVersion,
                        toDeviceAppBuildNumber: user.currentDevice.appBuildNumber,
                        toDeviceModel: user.currentDevice.model,
                        toDeviceOperatingSystemType: user.currentDevice.operatingSystemType,
                        toDeviceOperatingSystemVersion: user.currentDevice.operatingSystemVersion,
                    };

                    this.logger.info('CallRouterMachine', 'Start call ringing');
                    this.putCall.execute(payload, new IdQuery(context.metadata.callId));
                },
                showFeedback: async (context, event) => {
                    this.logger.info('CallRouterMachine', 'Show feedback');
                    const hasHighlights =
                        this.canStoreCallHighlights.execute(context.metadata) &&
                        (await this.hasCallHighlights.execute());
                    const hasPhotoboothImages = await this.hasPhotoboothImages.execute();
                    // Show Call Moments Modal if there are highlights (if has permissions) or photobooth images
                    if (hasHighlights || hasPhotoboothImages) {
                        await this.modalService.addModal(CallHighlightModalComponent).toPromise();
                    }
                    // Show feedback modal
                    switch (await this.getCallFeedbackModal.execute(context.callStartTime, context.interactionCount)) {
                        case ShowCallFeedbackModal.AppRating:
                            this.modalService.addModal(AppRatingModalComponent);
                            break;

                        case ShowCallFeedbackModal.CallFeedback:
                            this.modalService.addModal(CallFeedbackModalComponent, {
                                callId: context.metadata.callId,
                            });
                            break;
                    }
                },
                showPermissionsError: () => {
                    this.logger.info('CallRouterMachine', 'Show permissions error');
                    this.modalService.addModal(PermissionsDeniedModalComponent);
                },
                showFreeCallEndedWarning: (context, event) => {
                    const isFreePlanCall = this.isFreePlanCall.execute(
                        context.metadata.localParticipant,
                        context.metadata.remoteParticipant,
                    );
                    if (isFreePlanCall) {
                        this.modalService
                            .addModal(AlertModalComponent, {
                                title: 'Time is Up',
                                message: `Your free call is over, please consider upgrading to continue playing and reading on Together`,
                                type: 'warning',
                                icon: 'phone-o',
                                primaryActionLabel: 'Upgrade',
                                secondaryActionLabel: 'Cancel',
                            })
                            .subscribe(actionType => {
                                if (actionType === ActionType.Primary) {
                                    this.storeService.showUpgradeModal();
                                }
                            });
                    }
                },
                spawnCall: assign({
                    call: context => {
                        this.logger.info('CallRouterMachine', 'Spawn call machine');

                        // Create CallMachine with it's dependencies
                        const callMachine = this.callMachineFactory.create(context.metadata);

                        return spawn(callMachine, {
                            name: 'call',
                            sync: true,
                        });
                    },
                }),
                storeHighlight: async (context, event: PointScoredEvent) => {
                    // If either user doesn't want to save call highlights, abort
                    if (!this.canStoreCallHighlights.execute(context.metadata)) {
                        this.logger.info('CallRouterMachine', 'No permissions to store call highlights');
                        return;
                    }

                    if (!event.activityCapture || !event.localCapture || !event.remoteCapture) {
                        this.logger.info(
                            'CallRouterMachine',
                            'Event does not have the captures required to store  call highlights',
                        );
                        return;
                    }

                    // Resolve in parallel
                    const [activityCapture, localCapture, remoteCapture] = await Promise.all([
                        Promise.resolve(event.activityCapture),
                        event.localCapture,
                        event.remoteCapture,
                    ]);

                    this.putCallHighlight.execute({
                        activityCapture,
                        localCapture,
                        remoteCapture,
                    });
                },
                updateRemoteParticipant: (context, event: CallAcceptedEvent) => {
                    context.metadata.remoteParticipant.currentDevice = event.remoteParticipantDevice;
                },
            },
            delays: {
                // Two Minutes before considering the call Missed
                MISSED_DELAY: 120000,
            },
            services: {
                callStatusChange$: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Init call status observer.');
                    return this.callStatusObserver.execute(context.metadata.callId).pipe(
                        map((response: CallStatusWithRemoteParticipant) => {
                            if (response.status === CallStatus.Accepted) {
                                return {
                                    type: `CALL_STATUS_${response.status.toUpperCase()}`,
                                    remoteParticipantDevice: response.remoteParticipantDevice,
                                };
                            }
                            return {
                                type: `CALL_STATUS_${response.status.toUpperCase()}`,
                            };
                        }),
                    );
                },
                createCall: async (context, event) => {
                    this.logger.info('CallRouterMachine', 'Creating call…');

                    try {
                        const call = await this.createCall.execute(context.metadata);
                        this.logger.info('CallRouterMachine', 'Call created');

                        // Notify iOS users via push notification (ignore any error)
                        const sendCallNotification = this.functions.httpsCallable('sendCallNotification');

                        sendCallNotification({
                            callId: call.id,
                            toUserId: call.toUserId,
                            fromUserDisplayName: call.fromUserDisplayName,
                            fromUserId: call.fromUserId,
                            useVOIP: true,
                            pushEnvironment: environment.production ? 'prod' : 'dev',
                        }).catch(err => {
                            this.logger.error(
                                'CallRouterMachine',
                                `Could not 'sendCallNotification', continuing anyway`,
                            );
                        });
                    } catch (err) {
                        this.logger.error('CallRouterMachine', `Could not create call: ${err}`);

                        throw {
                            code: 'CREATE_CALL',
                            message: 'There was a problem while creating the call.',
                        };
                    }
                },
                endCallTimeout: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Init end-call timeout');

                    // Invoked Callback
                    return (callback, onReceive) => {
                        const timeout = context.callEndTime - Date.now();
                        const id = setTimeout(() => {
                            this.logger.info('CallRouterMachine', 'End call timeout reached. Hung-up.');

                            if (context.metadata.isFreeCall()) {
                                this.logAnalyticsEvent.execute(AnalyticsEvent.FreeCallRanOutOfTime);
                                callback('HUNG_UP_FREE_CALL');
                            } else {
                                this.logAnalyticsEvent.execute(AnalyticsEvent.CallExceededMaxLimit);
                                callback('HUNG_UP');
                            }
                        }, timeout);

                        // Clean-up function
                        return () => clearTimeout(id);
                    };
                },
                incomingCall$: (context, event) => {
                    this.logger.info('CallRouterMachine', 'Listening for incoming calls…');

                    return this.incomingCallObserver.execute(user).pipe(
                        map(metadata => {
                            //With 3 min ttl on incomingCallObserver, observer sends duplicate call records
                            //in rare cases esp on android when the app is quit and launched while the call is received
                            //To handle this, start the call only if it's a different call
                            if (!context?.metadata?.callId || context?.metadata?.callId !== metadata.callId) {
                                return {
                                    type: 'START_CALL',
                                    metadata,
                                };
                            }
                        }),
                    );
                },
                rejectIncomingCalls: (context, event) => {
                    // Invoked Callback
                    return (callback, onReceive) => {
                        this.logger.info('CallRouterMachine', 'Start reject-calls observer');

                        const subscription = this.incomingCallObserver.execute(user).subscribe(metadata => {
                            // Update incoming call state to busy (only if it's a different call)
                            if (context.metadata.callId !== metadata.callId) {
                                this.logger.info('CallRouterMachine', `Rejecting incoming call=${metadata.callId}`);

                                this.putCall.execute({ status: CallStatus.Userbusy }, new IdQuery(metadata.callId));
                            }
                        });

                        // Clean-up function
                        return () => {
                            this.logger.info('CallRouterMachine', 'Tear-down reject-calls observer');
                            subscription.unsubscribe();
                        };
                    };
                },
                showCallEndWarningTimeout: (context, event) => {
                    // Invoked Callback
                    return (callback, onReceive) => {
                        const twoMinutes = 2 * 60 * 1000;
                        const timeout = Math.max(context.callEndTime - Date.now() - twoMinutes, 1000);
                        const isFreePlanCall = this.isFreePlanCall.execute(
                            context.metadata.localParticipant,
                            context.metadata.remoteParticipant,
                        );

                        this.logger.info('CallRouterMachine', 'Start call-end warning timeout');

                        const id = setTimeout(() => {
                            if (isFreePlanCall) {
                                this.logger.info('CallRouterMachine', 'Show call-end warning');

                                this.modalService.addModal(AlertModalComponent, {
                                    title: 'Time’s Almost Up!',
                                    message: [
                                        // Hack to fix line length lint error
                                        'You only have 2 minutes of free call time left. <span class="__highlight_text--purple">Subscribe for more.</span>',
                                    ].join(' '),
                                    subText:
                                        'If you have any questions or feedback, please contact us at support@togethervideoapp.com',
                                    type: 'warning',
                                    icon: 'phone-o',
                                });
                            }
                        }, timeout);

                        // Clean-up function
                        return () => {
                            this.logger.info('CallRouterMachine', 'Tear-down call-end warning timeout');
                            clearTimeout(id);
                        };
                    };
                },
                showPermissionsPrompt: context => {
                    this.logger.info('CallRouterMachine', 'Show initial permissions prompt?');

                    return async callback => {
                        const OK_EVENT =
                            context.metadata.callMode === CallMode.Create ? 'OUTGOING_CALL' : 'INCOMING_CALL';

                        if (await this.shouldShowPermissionsPrompt.execute()) {
                            this.logger.info('CallRouterMachine', 'Show initial permissions prompt? => YES');
                            // Notify the user that she'll be asked for permissions
                            await this.modalService.addModal(PermissionsPromptModalComponent).toPromise();
                        }

                        return callback(OK_EVENT);
                    };
                },
                updateCallDurationStat: (context, event) => {
                    // Invoked Callback
                    return (callback, onReceive) => {
                        const interval = 240; // Update interval in seconds
                        let duration = 0;

                        this.logger.info('CallRouterMachine', 'Start call-duration update interval');

                        const id = setInterval(() => {
                            this.logger.info('CallRouterMachine', 'Update call duration');
                            duration += interval;
                            this.putCall.execute({ duration }, new IdQuery(context.metadata.callId));
                        }, interval * 1000);

                        // Clean-up function
                        return () => {
                            this.logger.info('CallRouterMachine', 'Tear-down call-duration updater');
                            clearInterval(id);
                        };
                    };
                },
                checkVonageCompatibility: (context, event) => {
                    this.logger.info('CallRouterService', 'Check for Vonage compatibility of remote user');
                    return async callback => {
                        const continueCall = await this.callRouterService.checkForVonageCompatibility(
                            context.metadata?.remoteParticipant,
                        );
                        if (!continueCall) {
                            this.logger.info(
                                'CallRouterService',
                                'Vonage migration is required, disconnecting in 10 secs',
                            );
                            setTimeout(() => {
                                callback('HUNG_UP');
                            }, 10000);
                        }
                    };
                },
            },
        });
    }
}
